import React, { Component } from "react";
import Header from "../Header";
import WebNotif from "../../components/WebNotif";
import Dropzone from "react-dropzone";
import { Prompt } from "react-router";
import Loader from "react-loader-spinner";
import { Link } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import brohchure from "../../assets/pdf/Calculator of CPD Points.xls";
// import * as yup from "yup";
// import * as yup from "yup";
import moment from "moment";
import Moment from "react-moment";
import {
  getFields,
  getSubCategories,
  getSubFields,
  getPoints,
  submitCPDForm,
  getClaimBasedSubmission,
  getUserSubmission,
  getUserDuplicateRecords,
} from "../../redux/user/useraction";
import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import LoadingOverlay from "react-loading-overlay";
import Select from "react-select";
import { Redirect } from "react-router-dom";
import api from "../../api";

const columns = [
  {
    name: "Category",
    label: "Category",
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: "Subcategory",
    label: "Subcategory",
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: "Email",
    label: "Email",
    options: {
      filter: false,
      sort: true,
    },
  },
  { name: "Course Name", label: "Course Name" },
  {
    name: "Fee",
    label: "Fee",
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: "Date Created",
    label: "Date Created",
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: "Session Name",
    label: "Session Name",
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: "Session StartDate",
    label: "Session StartDate",
    options: {
      filter: false,
      sort: true,
    },
  },
];
const yup = require("yup");
//image file types accepted
const acceptedFileTypes =
  "image/x-png, image/png, image/jpg, image/jpeg,application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/msword,";
const acceptedFileTypesArray = acceptedFileTypes.split(",").map((item) => {
  return item.trim();
});
let today = new Date();

// let postGraduateDiplomaSchema;
// let awardOfPostGraduateSchema;
// let awardOfDoctrateSchema;
// let workExperienceSchema;
// let membershipOfRecognizedSchema;
// let participationInTrainingSchema;
// let participationInConferenceSchema;
// let participationInSeminarSchema;
// let participationInLecturesSchema;
// let participationInWorkshopSchema;
// let refresherCourseSchema;
// let invitedLectureSchema;
// let symposiumSchema;
// let writingAndPublishingSchema;
// let writingConferencePaperSchema;
// let authoringBookSchema;
// let partOfActivitiesSchema;
// let participationInStatutorySchema;
// let managingTechnicalSchema;

const admissionDateBeforeFromDate = (admissionDate, from_date) => {
  return admissionDate <= from_date;
};

//validation of fileds using yup
let postGraduateDiplomaSchema = yup.object().shape({
  from_date: yup
    .date()
    .required("From Date To Calculate Period Is Required")
    .typeError("From Date To Calculate Period Is Required"),
  to_date: yup
    .date()
    .max(today)
    .required("To Date To Calculate Period Is Required")
    .typeError("To Date To Calculate Period Is Required"),
  mode_education: yup.string().required("Please Enter Mode Of Education"),
  uni_inst_name: yup
    .string()
    .required("Please Enter University / Institution Name"),
  title_diploma: yup.string().required("Please Enter Title Of Dimploma"),
  d_admission: yup
    .date()
    .required("Date of Admission Is Required")
    .typeError("Date of Admission Is Required")
    .test(
      "admission-date-before-from-date",
      "Admission date should be before From Date",
      function (admissionDate) {
        const from_date = this.parent.from_date; // Access from_date from the parent object
        return admissionDate <= from_date;
      }
    ),
  cpd_points_user: yup
    .number()
    .required("Please Enter CPD Points to Claim")
    .typeError("Please Enter CPD Points to Claim"),
  certificate_number: yup
    .number()
    .required("Certificate Number Is Required")
    .typeError("Certificate Number Is Required"),
});

let awardOfPostGraduateSchema = yup.object().shape({
  from_date: yup
    .date()
    .required("From Date To Calculate Period Is Required")
    .typeError("From Date To Calculate Period Is Required"),
  to_date: yup
    .date()
    .max(today)
    .required("To Date To Calculate Period Is Required")
    .typeError("To Date To Calculate Period Is Required"),
  mode_education: yup.string().required("Please Enter Mode Of Education"),
  uni_inst_name: yup
    .string()
    .required("Please Enter University / Institution Name"),
  title_degree: yup.string().required("Please Enter Title Of Degree"),
  d_admission: yup
    .date()
    .required("Date of Admission Is Required")
    .typeError("Date of Admission Is Required")
    .test(
      "admission-date-before-from-date",
      "Admission date should be before From Date",
      function (admissionDate) {
        const from_date = this.parent.from_date; // Access from_date from the parent object
        return admissionDate <= from_date;
      }
    ),
  cpd_points_user: yup
    .number()
    .required("Please Enter CPD Points to Claim")
    .typeError("Please Enter CPD Points to Claim"),
  certificate_number: yup
    .number()
    .required("Certificate Number Is Required")
    .typeError("Certificate Number Is Required"),
});

let awardOfDoctrateSchema = yup.object().shape({
  from_date: yup
    .date()
    .required("From Date To Calculate Period Is Required")
    .typeError("From Date To Calculate Period Is Required"),
  to_date: yup
    .date()
    .test(
      "is-minimum-duration",
      "Minimum Period required to Claim Points is 3 Years",
      function (value) {
        const from_date = this.resolve(yup.ref("from_date"));
        const durationInYears = moment(value).diff(moment(from_date), "years");
        return durationInYears >= 3;
      }
    )
    .required("To Date To Calculate Period Is Required")
    .typeError("To Date To Calculate Period Is Required"),

  mode_education: yup.string().required("Mode Of Education Is Required"),
  uni_inst_name: yup
    .string()
    .required("Please Enter University / Institution Name"),
  title_degree: yup.string().required("Please Enter Title Of Degree"),
  d_admission: yup
    .date()
    .required("Date of Admission Is Required")
    .typeError("Date of Admission Is Required")
    .test(
      "admission-date-before-from-date",
      "Admission date should be before From Date",
      function (admissionDate) {
        const from_date = this.parent.from_date; // Access from_date from the parent object
        return admissionDate <= from_date;
      }
    ),
  cpd_points_user: yup
    .number()
    .required("Please Enter CPD Points to Claim")
    .typeError("Please Enter CPD Points to Claim"),
  certificate_number: yup
    .number()
    .required("Certificate Number Is Required")
    .typeError("Certificate Number Is Required"),
});

let workExperienceSchema = yup.object().shape({
  from_date: yup
    .date()
    .required("From Date To Calculate Period Is Required")
    .typeError("From Date To Calculate Period Is Required"),
  to_date: yup
    .date()
    .max(today)
    .required("To Date To Calculate Period Is Required")
    .typeError("To Date To Calculate Period Is Required"),
  name_organization: yup
    .string()
    .required("Please Enter Name of Institution / Professional Body"),
  designation: yup.string().required("Designation Is Required"),
  cpd_points_user: yup
    .number()
    .required("Please Enter CPD Points to Claim")
    .typeError("Please Enter CPD Points to Claim"),
  certificate_number: yup
    .number()
    .required("Certificate Number Is Required")
    .typeError("Certificate Number Is Required"),
});

let membershipOfRecognizedSchema = yup.object().shape({
  n_p_body: yup
    .string()
    .required("Please Enter Name of Institution / Professional Body"),
  year_reg: yup
    .date()
    .required("Please Enter Year Of Registration")
    .typeError("Please Enter Year Of Registration"),
  renew_upto: yup
    .date()
    .required("Renew Upto Is Required")
    .typeError("Renew Upto Is Required"),
  cpd_points_user: yup
    .number()
    .required("Please Enter CPD Points to Claim")
    .typeError("Please Enter CPD Points to Claim"),
  certificate_number: yup
    .number()
    .required("Certificate Number Is Required")
    .typeError("Certificate Number Is Required"),
});
let participationInTrainingSchema = yup.object().shape({
  from_date: yup
    .date()
    .required("From Date To Calculate Period Is Required")
    .typeError("From Date To Calculate Period Is Required"),
  to_date: yup
    .date()
    .max(today)
    .required("To Date To Calculate Period Is Required")
    .typeError("To Date To Calculate Period Is Required"),
  title_training: yup.string().required("Please Enter Title Of Training"),
  name_inst_pbody: yup
    .string()
    .required("Please Enter Name of Institution / Professional Body"),
  cpd_points_user: yup
    .number()
    .required("Please Enter CPD Points to Claim")
    .typeError("Please Enter CPD Points to Claim"),
  certificate_number: yup
    .number()
    .required("Certificate Number Is Required")
    .typeError("Certificate Number Is Required"),
});
let participationInConferenceSchema = yup.object().shape({
  t_conference: yup.string().required("Please Enter Title Of Conference"),
  name_inst_pbody: yup
    .string()
    .required("Please Enter Name of Institution / Professional Body"),
  cpd_points_user: yup
    .number()
    .required("Please Enter CPD Points to Claim")
    .typeError("Please Enter CPD Points to Claim"),
  certificate_number: yup
    .number()
    .required("Certificate Number Is Required")
    .typeError("Certificate Number Is Required"),
  participation_date: yup
    .date()
    .required("Participation Date Is Required")
    .typeError("Participation Date Is Required"),
});

let participationInSeminarSchema = yup.object().shape({
  title_seminar: yup.string().required("Please Enter Title Of Seminar"),
  name_inst_pbody: yup
    .string()
    .required("Please Enter Name of Institution / Professional Body"),
  cpd_points_user: yup
    .number()
    .required("Please Enter CPD Points to Claim")
    .typeError("Please Enter CPD Points to Claim"),
  certificate_number: yup
    .number()
    .required("Certificate Number Is Required")
    .typeError("Certificate Number Is Required"),
  participation_date: yup
    .date()
    .required("Participation Date Is Required")
    .typeError("Participation Date Is Required"),
});
let participationInLecturesSchema = yup.object().shape({
  title_lecture: yup.string().required("Please Enter Title Of Seminar"),
  name_inst_pbody: yup
    .string()
    .required("Please Enter Name of Institution / Professional Body"),
  cpd_points_user: yup
    .number()
    .required("Please Enter CPD Points to Claim")
    .typeError("Please Enter CPD Points to Claim"),
  certificate_number: yup
    .number()
    .required("Certificate Number Is Required")
    .typeError("Certificate Number Is Required"),
  participation_date: yup
    .date()
    .required("Participation Date Is Required")
    .typeError("Participation Date Is Required"),
});
let participationInWorkshopSchema = yup.object().shape({
  title_Workshop: yup.string().required("Please Enter Title Of Workshop"),
  name_inst_pbody: yup
    .string()
    .required("Please Enter Name of Institution / Professional Body"),
  cpd_points_user: yup
    .number()
    .required("Please Enter CPD Points to Claim")
    .typeError("Please Enter CPD Points to Claim"),
  certificate_number: yup
    .number()
    .required("Certificate Number Is Required")
    .typeError("Certificate Number Is Required"),
  participation_date: yup
    .date()
    .required("Participation Date Is Required")
    .typeError("Participation Date Is Required"),
});
let refresherCourseSchema = yup.object().shape({
  name_inst_pbody: yup
    .string()
    .required("Please Enter Name of Institution / Professional Body"),
  cpd_points_user: yup
    .number()
    .required("Please Enter CPD Points to Claim")
    .typeError("Please Enter CPD Points to Claim"),
  certificate_number: yup
    .number()
    .required("Certificate Number Is Required")
    .typeError("Certificate Number Is Required"),
  participation_date: yup
    .date()
    .required("Participation Date Is Required")
    .typeError("Participation Date Is Required"),
});

let invitedLectureSchema = yup.object().shape({
  title_lecture: yup.string().required("Please Enter Title of Lecture"),
  name_inst_pbody: yup
    .string()
    .required("Please Enter Name of Institution / Professional Body"),
  dateOfEvent: yup
    .date()
    .required("Date of event is required")
    .typeError("Date of event is required"),
  cpd_points_user: yup
    .number()
    .required("Please Enter CPD Points to Claim")
    .typeError("Please Enter CPD Points to Claim"),
  certificate_number: yup
    .number()
    .required("Certificate Number Is Required")
    .typeError("Certificate Number Is Required"),
});
let symposiumSchema = yup.object().shape({
  from_date: yup
    .date()
    .required("From Date To Calculate Period Is Required")
    .typeError("From Date To Calculate Period Is Required"),
  to_date: yup
    .date()
    .max(today)
    .required("To Date To Calculate Period Is Required")
    .typeError("To Date To Calculate Period Is Required"),
  title_symposium: yup.string().required("Please Enter Title Of Symposium"),
  name_inst_pbody: yup
    .string()
    .required("Please Enter Name of Institution / Professional Body"),
  dateOfEvent: yup
    .date()
    .required("Date of event is required")
    .typeError("Date of event is required"),
  cpd_points_user: yup
    .number()
    .required("Please Enter CPD Points to Claim")
    .typeError("Please Enter CPD Points to Claim"),
  certificate_number: yup
    .number()
    .required("Certificate Number Is Required")
    .typeError("Certificate Number Is Required"),
});
let writingAndPublishingSchema = yup.object().shape({
  title_research_article: yup
    .string()
    .required("Please Enter Title of Research Article"),
  n_journal: yup.string().required("Please Enter Name of Journal"),
  publish_date: yup
    .date()
    .required("Publish Date Is Required")
    .typeError("Publish Date Is Required"),
  cpd_points_user: yup
    .number()
    .required("Please Enter CPD Points to Claim")
    .typeError("Please Enter CPD Points to Claim"),
  certificate_number: yup
    .number()
    .required("Certificate Number Is Required")
    .typeError("Certificate Number Is Required"),
});
let writingConferencePaperSchema = yup.object().shape({
  title_conference_paper: yup
    .string()
    .required("Please Enter Title of Conference paper"),
  name_host_org: yup
    .string()
    .required("Please Enter Name of Host Organization"),
  locationInput: yup.string().required("Location is required"),
  cpd_points_user: yup
    .number()
    .required("Please Enter CPD Points to Claim")
    .typeError("Please Enter CPD Points to Claim"),
  certificate_number: yup
    .number()
    .required("Certificate Number Is Required")
    .typeError("Certificate Number Is Required"),
});
let authoringBookSchema = yup.object().shape({
  t_book: yup.string().required("Please Enter Title of Book"),
  book_publisher: yup.string().required("Please Enter Name of Book Publisher"),
  approving_authority: yup
    .string()
    .required("Please Enter The Name Of Approving Authority"),
  publish_date: yup
    .date()
    .required("Publishing Date Is Required")
    .typeError("Publishing Date Is Required"),

  cpd_points_user: yup
    .number()
    .required("Please Enter CPD Points to Claim")
    .typeError("Please Enter CPD Points to Claim"),
  certificate_number: yup
    .number()
    .required("Certificate Number Is Required")
    .typeError("Certificate Number Is Required"),
});
let partOfActivitiesSchema = yup.object().shape({
  nature_social_work: yup
    .string()
    .required("Please Enter The Nature Of Social Work"),
  r_social_work: yup.string().required("Please Enter The Role In Social Work"),
  d_activity: yup
    .date()
    .required("Date Of Activity Is Required")
    .typeError("Date Of Activity Is Required"),
  cpd_points_user: yup
    .number()
    .required("Please Enter CPD Points to Claim")
    .typeError("Please Enter CPD Points to Claim"),
  certificate_number: yup
    .number()
    .required("Certificate Number Is Required")
    .typeError("Certificate Number Is Required"),
});
let participationInStatutorySchema = yup.object().shape({
  nature_committee: yup
    .string()
    .required("Please Enter The Nature Of Committee"),
  role_committee: yup.string().required("Please Enter The Role In Committee"),
  notifying_authority: yup
    .string()
    .required("Please Enter The Notifying Authority Name"),
  year_task_assigned: yup
    .date()
    .required("Year of task assigned is required")
    .typeError("Year of task assigned is required"),
  year_task_completed: yup
    .date()
    .required("Year of task completed is required")
    .typeError("Year of task completed is required"),
  cpd_points_user: yup
    .number()
    .required("Please Enter CPD Points to Claim")
    .typeError("Please Enter CPD Points to Claim"),
  certificate_number: yup
    .number()
    .required("Certificate Number Is Required")
    .typeError("Certificate Number Is Required"),
});
let managingTechnicalSchema = yup.object().shape({
  nature_event: yup.string().required("Please Enter Nature Of Event"),
  role_event: yup.string().required("Please Enter The Role In Event"),
  location_event: yup.string().required("Location Of Event Is Required"),
  dateOfEvent: yup
    .date()
    .required("Date of event is required")
    .typeError("Date of event is required"),

  cpd_points_user: yup
    .number()
    .required("Please Enter CPD Points to Claim")
    .typeError("Please Enter CPD Points to Claim"),
  certificate_number: yup
    .number()
    .required("Certificate Number Is Required")
    .typeError("Certificate Number Is Required"),
});

const participation_array = [
  "Participation in  Conference",
  "Participation in Seminar",
  "Participation in Lectures",
  "Participation in Workshop",
  "Refresher Course/ short course ",
];
const activity_array = ["Writing Conference paper"];

var obj = new WebNotif();
const modeofeducation = [
  { value: "", label: "Select Mode Of Education" },
  { value: "Regular / Physical", label: "Regular / Physical" },
  { value: "Online / Virtual", label: "Online / Virtual" },
  { value: "Other", label: "Other" },
];
class ClaimCPD extends Component {
  state = {
    UserID: null,
    image64: null,
    upload: null,
    currentFileTypeofData: "",
    imageFile: null,
    currentFileNameofData: "",
    loader: false,
    selectMode: modeofeducation[0],
    selectedCat: { value: "", label: "Select a Category" },
    selectedSubCat: { value: "", label: "Select a Sub Category" },
    formID: "",
    formList: [],
    globalForms: [],
    formMui: [],
    fileUploadUrl: null,
    setFinalApprovalLoad: false,
    formData: {
      toformalperiod: "",
      formaldata: "",
      select_category: "",
      sub_category: "",
      creditHours: "",
      ref_link: "",
      title_lecture: "",
      course_work: "",
      paperType: "",
      cpd_points_pec: "----",
      from_date: "",
      daysDuration: "",
      hoursDuration: "",
      suggestion: "",
      to_date: "",
      period: "",
      title_symposium: "",
      title_research_article: "",
      n_journal: "",
      publish_date: "",
      t_conference: "",
      title_training: "",
      title_conference_paper: "",
      name_host_org: "",
      locationInput: "",
      t_book: "",
      book_publisher: "",
      publishingDate: "",
      approving_authority: "",
      nature_social_work: "",
      nature_committee: "",
      role_committee: "",
      nature_event: "",
      role_event: "",
      tors_committee: "",
      notifying_authority: "",
      year_task_assigned: "",
      year_task_completed: "",
      r_social_work: "",
      d_activity: "",
      n_p_body: "",
      name_inst_pbody: "",
      uni_inst_name: "",
      name_organization: "",
      location_event: "",
      designation: "",
      nature_participation: "",
      d_admission: "",
      dateOfEvent: "",
      year_reg: "",
      renew_upto: "",
      publicationRadioOptions: "",
      organizationTypeRadioOptions: "",
      event_type: "",
      organizing_body: "",
      OrganizingBodyRadioOptions1: "",
      OrganizingBodyRadioOptions2: "",
      membership_type: "",
      mode_education: null,
      localForeignBody: "",
      inst_type: "",
      professional_body_type: "",
      natureOfParticipationRadioOptions1: "",
      natureOfParticipationRadioOptions2: "",
      natureOfParticipationRadioOptions3: "",
      nature_activities: "",
      title_diploma: "",
      title_degree: "",
      cpd_points_user: "",
      upload: null,
      title_seminar: "",
      title_Workshop: "",
      title_course_short: "",
      shouldBlockNavigation: true,
      certificate_number: "",
      claim_date_field: "",
      participation_date: "",
    },
  };

  setDefaultState = () => {
    this.setState(
      {
        selectMode: modeofeducation[0],
        selectedCat: { value: "", label: "Select a Category" },
        selectedSubCat: { value: "", label: "Select a Sub Category" },
        formData: {
          selectMode: modeofeducation[0],
          toformalperiod: "",
          formaldata: "",
          select_category: "",
          sub_category: "",
          creditHours: "",
          title_lecture: "",
          course_work: "",
          ref_link: "",
          paperType: "",
          cpd_points_pec: "----",
          from_date: "",
          suggestion: "",
          hoursDuration: "",
          daysDuration: "",
          to_date: "",
          period: "",
          title_symposium: "",
          title_research_article: "",
          n_journal: "",
          publish_date: "",
          t_conference: "",
          title_training: "",
          title_conference_paper: "",
          name_host_org: "",
          locationInput: "",
          t_book: "",
          book_publisher: "",
          nature_participation: "",
          publishingDate: "",
          approving_authority: "",
          nature_social_work: "",
          nature_committee: "",
          role_committee: "",
          nature_event: "",
          role_event: "",
          tors_committee: "",
          notifying_authority: "",
          year_task_assigned: "",
          year_task_completed: "",
          r_social_work: "",
          d_activity: "",
          n_p_body: "",
          name_inst_pbody: "",
          uni_inst_name: "",
          name_organization: "",
          location_event: "",
          designation: "",
          d_admission: "",
          dateOfEvent: "",
          year_reg: "",
          renew_upto: "",
          publicationRadioOptions: "",
          organizationTypeRadioOptions: "",
          event_type: "",
          organizing_body: "",
          OrganizingBodyRadioOptions1: "",
          OrganizingBodyRadioOptions2: "",
          membership_type: "",
          mode_education: null,
          localForeignBody: "",
          inst_type: "",
          professional_body_type: "",
          natureOfParticipationRadioOptions1: "",
          natureOfParticipationRadioOptions2: "",
          natureOfParticipationRadioOptions3: "",
          nature_activities: "",
          title_diploma: "",
          title_degree: "",
          cpd_points_user: "",
          certificate_number: "",
          participation_date: "",
          claim_date_field: "",
          upload: null,
          title_seminar: "",
          title_Workshop: "",
          title_course_short: "",
          shouldBlockNavigation: true,
        },
      },
      () => {
        this.setState({ fieldsSet: "" });
      }
    );
  };

  setDefaultSubCatState = () => {
    this.setState(
      {
        selectMode: modeofeducation[0],
        // selectedCat: { value: "", label: "Select a Category" },
        // selectedSubCat: { value: "", label: "Select a Sub Category" },
        formData: {
          selectMode: modeofeducation[0],
          toformalperiod: "",
          formaldata: "",
          select_category: "",
          sub_category: "",
          creditHours: "",
          title_lecture: "",
          course_work: "",
          ref_link: "",
          paperType: "",
          cpd_points_pec: "----",
          from_date: "",
          suggestion: "",
          hoursDuration: "",
          daysDuration: "",
          to_date: "",
          period: "",
          title_symposium: "",
          title_research_article: "",
          n_journal: "",
          publish_date: "",
          t_conference: "",
          title_training: "",
          title_conference_paper: "",
          name_host_org: "",
          locationInput: "",
          t_book: "",
          book_publisher: "",
          nature_participation: "",
          publishingDate: "",
          approving_authority: "",
          nature_social_work: "",
          nature_committee: "",
          role_committee: "",
          nature_event: "",
          role_event: "",
          tors_committee: "",
          notifying_authority: "",
          year_task_assigned: "",
          year_task_completed: "",
          r_social_work: "",
          d_activity: "",
          n_p_body: "",
          name_inst_pbody: "",
          uni_inst_name: "",
          name_organization: "",
          location_event: "",
          designation: "",
          d_admission: "",
          dateOfEvent: "",
          year_reg: "",
          renew_upto: "",
          publicationRadioOptions: "",
          organizationTypeRadioOptions: "",
          event_type: "",
          organizing_body: "",
          OrganizingBodyRadioOptions1: "",
          OrganizingBodyRadioOptions2: "",
          membership_type: "",
          mode_education: null,
          localForeignBody: "",
          inst_type: "",
          professional_body_type: "",
          natureOfParticipationRadioOptions1: "",
          natureOfParticipationRadioOptions2: "",
          natureOfParticipationRadioOptions3: "",
          nature_activities: "",
          title_diploma: "",
          title_degree: "",
          cpd_points_user: "",
          certificate_number: "",
          participation_date: "",
          claim_date_field: "",
          upload: null,
          title_seminar: "",
          title_Workshop: "",
          title_course_short: "",
          shouldBlockNavigation: true,
        },
      },
      () => {
        this.setState({ fieldsSet: "" });
      }
    );
  };

  //submit final checkout
  submitFinal = (e) => {
    e.preventDefault();
    if (
      this.state.globalForms.length > 0 &&
      this.state.formList.length > 0 &&
      this.state.formMui.length > 0
    ) {
      this.state.globalForms.map((item, index) => {
        let fd = new FormData();
        fd.append("file_claim", item.file_claim1);
        fd.append("form_body", JSON.stringify(item.form_body));
        fd.append("UserID", item.UserID);
        fd.append("form_id", item.form_id);
        fd.append("fildsSet", JSON.stringify(item.fildsSet));
        this.setState({ setFinalApprovalLoad: true });
        this.props
          .submitCPDForm(fd)
          .then((res) => {
            if (res.data.message) {
              this.setState({ setFinalApprovalLoad: false });
              localStorage.removeItem("globalForms");
              localStorage.removeItem("formMui");
              localStorage.removeItem("formList");
              setTimeout(function () {
                window.location.reload();
              }, 2000);
              obj.createNotification(
                "error",
                "Something went wrong. Please try again"
              );
            } else {
              this.setState({ setFinalApprovalLoad: false });
              localStorage.removeItem("globalForms");
              localStorage.removeItem("formMui");
              localStorage.removeItem("formList");
              // setTimeout(function () {
              //   window.location.reload();
              // }, 2000);
              obj.createNotification(
                "success",
                "You've Successfully Claimed For CPD Points. Your Request Will Be Reviewed And Approved By Our Admin Panel."
              );
              // <Redirect to="/CPD/CpdFormProfile" />;
            }
            this.setState({ loader: false });
            setTimeout(function () {
              window.location.reload();
            }, 1200);
            // this.setDefaultState();
          })
          .catch((err) => {
            // this.setState({ loader: false });
            this.setState({ setFinalApprovalLoad: false });
            localStorage.removeItem("globalForms");
            localStorage.removeItem("formMui");
            localStorage.removeItem("formList");
            setTimeout(function () {
              window.location.reload();
            }, 2000);
            console.log(err.response);
          });
      });
    }
  };

  checkAndNotify = (type, claimDate, fieldName, errorMsg) => {
    if (!claimDate) {
      obj.createNotification("error", `${fieldName} ${errorMsg} required`);
      return;
      // You can add additional logic here if needed
    }
  };

  //form handle and insertion to dataabse with appending multiform data
  formHandle = (e) => {
    e.preventDefault();
    this.setState({ loader: false }, async () => {
      if (
        this.state.formData.upload === null ||
        this.state.formData.upload === undefined
      ) {
        this.setState({ loader: false });
        obj.createNotification(
          "error",
          "Document For Claiming CPD Points Is Required"
        );
      } else {
        let claimDate = null;
        let type = null;
        let field_id = null;
        if (this.state.selectedCat.value === "Formal Education") {
          claimDate = this.state.formData.to_date;
          type = "to_date";
          field_id = "9";
          this.checkAndNotify(type, claimDate, "Both from and to dates", "are");
        } else if (this.state.selectedCat.value === "Work Based Activities") {
          claimDate = this.state.formData.to_date;
          type = "to_date";
          field_id = "9";
          this.checkAndNotify(type, claimDate, "Both from and to dates", "are");
        } else if (
          this.state.selectedCat.value === "Developmental Activities"
        ) {
          if (
            this.state.selectedSubCat.label ===
            "Membership of a recognized professional  association/ body"
          ) {
            claimDate = this.state.formData.year_reg;
            type = "year_reg";
            field_id = "18";
            this.checkAndNotify(type, claimDate, "Year of Registration", "is");
          } else if (
            this.state.selectedSubCat.label ===
            "Participation in  Training & Development programmes"
          ) {
            claimDate = this.state.formData.to_date;
            type = "to_date";
            field_id = "9";
            this.checkAndNotify(
              type,
              claimDate,
              "Both from and to dates",
              "are"
            );
          } else if (
            this.state.selectedSubCat.label === "Participation in  Conference"
          ) {
            claimDate = this.state.formData.participation_date;
            type = "participation_date";
            field_id = "63";
            this.checkAndNotify(type, claimDate, "Participation Date", "is");
          } else if (
            this.state.selectedSubCat.label === "Participation in Seminar"
          ) {
            claimDate = this.state.formData.participation_date;
            type = "participation_date";
            field_id = "63";
            this.checkAndNotify(type, claimDate, "Participation Date", "is");
          } else if (
            this.state.selectedSubCat.label === "Participation in Lectures"
          ) {
            claimDate = this.state.formData.participation_date;
            type = "participation_date";
            field_id = "63";
            this.checkAndNotify(type, claimDate, "Participation Date", "is");
          } else if (
            this.state.selectedSubCat.label === "Participation in Workshop"
          ) {
            claimDate = this.state.formData.participation_date;
            type = "participation_date";
            field_id = "63";
            this.checkAndNotify(type, claimDate, "Participation Date", "is");
          } else if (
            this.state.selectedSubCat.label ===
            "Refresher Course/ short course "
          ) {
            claimDate = this.state.formData.participation_date;
            type = "participation_date";
            field_id = "63";
            this.checkAndNotify(type, claimDate, "Participation Date", "is");
          }
        } else if (this.state.selectedCat.value === "Individual  Activities") {
          if (this.state.selectedSubCat.label === "Invited lecture ") {
            claimDate = this.state.formData.dateOfEvent;
            type = "date_event";
            field_id = "56";
            this.checkAndNotify(type, claimDate, "Date of Event", "is");
          } else if (this.state.selectedSubCat.label === "Symposium panelist") {
            claimDate = this.state.formData.to_date;
            type = "to_date";
            field_id = "9";
            this.checkAndNotify(
              type,
              claimDate,
              "Both from and to dates",
              "are"
            );
          } else if (
            this.state.selectedSubCat.label ===
            "Writing and publishing a research article in Journals/Technical report"
          ) {
            claimDate = this.state.formData.publish_date;
            type = "publish_date";
            field_id = "28";
            this.checkAndNotify(type, claimDate, "Publish Date", "is");
          } else if (
            this.state.selectedSubCat.label === "Writing Conference paper"
          ) {
            claimDate = this.state.formData.participation_date;
            type = "participation_date";
            field_id = "63";
            this.checkAndNotify(type, claimDate, "Participation Date", "is");
          } else if (this.state.selectedSubCat.label === "Authoring a Book") {
            claimDate = this.state.formData.publish_date;
            type = "publish_date";
            field_id = "28";
            this.checkAndNotify(type, claimDate, "Publish Date", "is");
          } else if (
            this.state.selectedSubCat.label ===
            "Part of activities such as emergency/ recognized social work"
          ) {
            claimDate = this.state.formData.d_activity;
            type = "d_activity";
            field_id = "44";
            this.checkAndNotify(type, claimDate, "Date of Activity", "is");
          } else if (
            this.state.selectedSubCat.label ===
            "Participation in statutory. Technical or non-technical committees"
          ) {
            claimDate = this.state.formData.year_task_completed;
            type = "year_task_completed";
            field_id = "41";
            this.checkAndNotify(type, claimDate, "Year Task Completed", "is");
          } else if (
            this.state.selectedSubCat.label === "Managing Technical event"
          ) {
            claimDate = this.state.formData.dateOfEvent;
            type = "date_event";
            field_id = "56";
            this.checkAndNotify(type, claimDate, "Date of Event", "is");
          }
        }

        if (!claimDate) {
          obj.createNotification("error", "Claim Date range is required");
        } else {
          const claimBasedData = {
            UserID: this.props.User.UserID,
            claimDate: claimDate,
            category: this.state.selectedCat.value,
            subCategory: this.state.selectedSubCat.label,
            fieldType: type,
            fieldId: field_id,
          };

          this.props
            .getClaimBasedSubmission(claimBasedData)
            .then((res) => {
              let arr = res.data.filter(
                (result) => result.category === this.state.selectedCat.value
              );
              let points = [];
              points.push(
                arr.filter((result) => result.final_approved_points != null)
              );
              let sum = 0;
              points[0].map((item, index) => {
                sum += item.final_approved_points;
              });
              // if (
              //   this.state.selectedCat.value === "Formal Education" &&
              //   sum >= 4
              // ) {
              //   this.setState({ loader: false });
              //   obj.createNotification(
              //     "error",
              //     "You have already claimed 4 credit points for this year"
              //   );
              // } else if (
              //   this.state.selectedCat.value === "Work Based Activities" &&
              //   sum >= 2
              // ) {
              //   this.setState({ loader: false });
              //   obj.createNotification(
              //     "error",
              //     "You have already claimed 2 credit points for this year"
              //   );
              // } else
              // if (
              //   this.state.selectedCat.value === "Developmental Activities" &&
              //   sum >= 3
              // ) {
              //   this.setState({ loader: false });
              //   obj.createNotification(
              //     "error",
              //     "You have already claimed 3 credit points for this year"
              //   );
              // } else if (
              //   this.state.selectedCat.value === "Individual  Activities" &&
              //   sum >= 3
              // ) {
              //   this.setState({ loader: false });
              //   obj.createNotification(
              //     "error",
              //     "You have already claimed 3 credit points for this year"
              //   );
              // } else {
              if (
                this.state.selectedSubCat.label == "Post Graduate Diploma (PGD)"
              ) {
                postGraduateDiplomaSchema
                  .validate(this.state.formData)
                  .then((validation) => {
                    const data = {
                      file_claim: JSON.stringify(this.state.imageFile),
                      file_claim1: this.state.imageFile,
                      form_body: validation,
                      UserID: this.state.UserID,
                      form_id: this.state.formID,
                      fildsSet: this.state.fildsSet,
                    };

                    this.props
                      .getUserDuplicateRecords(data)
                      .then((res) => {
                        console.log("DUPLICATE RECORD RESSSSS ... ", res);
                        const { isDuplicate, isCertificateDuplicate } =
                          res.data;
                        if (!!isDuplicate) {
                          if (!!isCertificateDuplicate) {
                            obj.createNotification(
                              "error",
                              "You have already submitted this certificate"
                            );
                          } else {
                            obj.createNotification(
                              "error",
                              "You have already submitted this claim"
                            );
                          }
                        } else {
                          this.setState(
                            () => {
                              return {
                                ...this.state,
                                globalForms: this.state.globalForms.concat({
                                  file_claim: JSON.stringify(
                                    this.state.imageFile
                                  ),
                                  file_claim1: this.state.imageFile,
                                  form_body: validation,
                                  UserID: this.state.UserID,
                                  form_id: this.state.formID,
                                  fildsSet: this.state.fildsSet,
                                }),
                              };
                            },
                            () => {
                              localStorage.setItem(
                                "globalForms",
                                JSON.stringify(this.state.globalForms)
                              );
                            }
                          );

                          this.setState(
                            () => {
                              return {
                                ...this.state,
                                formList: this.state.formList.concat(
                                  this.state.formData
                                ),
                              };
                            },
                            () => {
                              this.setState(
                                () => {
                                  let a = [];
                                  this.state.formList.map((item) => {
                                    let b = Object.values(item);
                                    let c = [b[2], b[1], b[0]];
                                    a.push(c);
                                  });
                                  return {
                                    ...this.state,
                                    formMui: a,
                                  };
                                },
                                () => {
                                  localStorage.setItem(
                                    "formMui",
                                    JSON.stringify(this.state.formMui)
                                  );
                                  localStorage.setItem(
                                    "formList",
                                    JSON.stringify(this.state.formList)
                                  );
                                }
                              );
                            }
                          );
                          // Put form data in list
                          this.setDefaultState();

                          <Redirect to="/CPD/CpdFormProfile" />;
                        }
                      })
                      .catch((err) => {
                        console.log("USER DUPLICATE RECORD ERR ... ", err);
                      });
                  })
                  .catch((err) => {
                    this.setState({ loader: false });
                    console.log(err);
                    obj.createNotification("error", err.message);
                  });
              } else if (
                this.state.selectedSubCat.label ==
                "Award of Post Graduate Degree (Engg, IT, Finance,Management, Law, Economics etc.)"
              ) {
                awardOfPostGraduateSchema
                  .validate(this.state.formData)
                  .then((validation) => {
                    if (
                      this.state.formData.inst_type === undefined ||
                      this.state.formData.inst_type === ""
                    ) {
                      this.setState({ loader: false });
                      obj.createNotification(
                        "error",
                        "Institution Type is Required"
                      );
                    } else if (
                      this.state.formData.course_work === undefined ||
                      this.state.formData.course_work === ""
                    ) {
                      this.setState({ loader: false });
                      obj.createNotification(
                        "error",
                        "Please Select the Course Work"
                      );
                    } else {
                      const data = {
                        file_claim: JSON.stringify(this.state.imageFile),
                        file_claim1: this.state.imageFile,
                        form_body: validation,
                        UserID: this.state.UserID,
                        form_id: this.state.formID,
                        fildsSet: this.state.fildsSet,
                      };

                      this.props
                        .getUserDuplicateRecords(data)
                        .then((res) => {
                          console.log("DUPLICATE RESSS ... ", res);
                          const { isDuplicate } = res.data;
                          if (!!isDuplicate) {
                            obj.createNotification(
                              "error",
                              "You have already submitted this claim"
                            );
                          } else {
                            this.setState(
                              () => {
                                return {
                                  ...this.state,
                                  globalForms: this.state.globalForms.concat({
                                    file_claim: JSON.stringify(
                                      this.state.imageFile
                                    ),
                                    file_claim1: this.state.imageFile,
                                    form_body: validation,
                                    UserID: this.state.UserID,
                                    form_id: this.state.formID,
                                    fildsSet: this.state.fildsSet,
                                  }),
                                };
                              },
                              () => {
                                localStorage.setItem(
                                  "globalForms",
                                  JSON.stringify(this.state.globalForms)
                                );
                              }
                            );

                            this.setState(
                              () => {
                                return {
                                  ...this.state,
                                  formList: this.state.formList.concat(
                                    this.state.formData
                                  ),
                                };
                              },
                              () => {
                                this.setState(
                                  () => {
                                    let a = [];
                                    this.state.formList.map((item) => {
                                      let b = Object.values(item);
                                      let c = [b[2], b[1], b[0]];
                                      a.push(c);
                                    });
                                    return {
                                      ...this.state,
                                      formMui: a,
                                    };
                                  },
                                  () => {
                                    localStorage.setItem(
                                      "formMui",
                                      JSON.stringify(this.state.formMui)
                                    );
                                    localStorage.setItem(
                                      "formList",
                                      JSON.stringify(this.state.formList)
                                    );
                                  }
                                );
                              }
                            );
                            this.setDefaultState();
                          }
                        })
                        .catch((err) => {
                          console.log("USER DUPLICATE RECORD ERR ... ", err);
                        });
                    }
                  })
                  .catch((err) => {
                    this.setState({ loader: false });
                    console.log(err);
                    obj.createNotification("error", err.message);
                  });
              } else if (
                this.state.selectedSubCat.label ==
                "Award of Doctorate (Ph.D) in Engg"
              ) {
                awardOfDoctrateSchema
                  .validate(this.state.formData)
                  .then((validation) => {
                    if (
                      this.state.formData.inst_type === undefined ||
                      this.state.formData.inst_type === ""
                    ) {
                      this.setState({ loader: false });
                      obj.createNotification(
                        "error",
                        "Institution Type is Required"
                      );
                    } else {
                      const data = {
                        file_claim: JSON.stringify(this.state.imageFile),
                        file_claim1: this.state.imageFile,
                        form_body: validation,
                        UserID: this.state.UserID,
                        form_id: this.state.formID,
                        fildsSet: this.state.fildsSet,
                      };

                      this.props
                        .getUserDuplicateRecords(data)
                        .then((res) => {
                          const { isDuplicate } = res.data;
                          if (!!isDuplicate) {
                            obj.createNotification(
                              "error",
                              "You have already submitted this claim"
                            );
                          } else {
                            this.setState(
                              () => {
                                return {
                                  ...this.state,
                                  globalForms: this.state.globalForms.concat({
                                    file_claim: JSON.stringify(
                                      this.state.imageFile
                                    ),
                                    file_claim1: this.state.imageFile,
                                    form_body: validation,
                                    UserID: this.state.UserID,
                                    form_id: this.state.formID,
                                    fildsSet: this.state.fildsSet,
                                  }),
                                };
                              },
                              () => {
                                localStorage.setItem(
                                  "globalForms",
                                  JSON.stringify(this.state.globalForms)
                                );
                              }
                            );

                            this.setState(
                              () => {
                                return {
                                  ...this.state,
                                  formList: this.state.formList.concat(
                                    this.state.formData
                                  ),
                                };
                              },
                              () => {
                                this.setState(
                                  () => {
                                    let a = [];
                                    this.state.formList.map((item) => {
                                      let b = Object.values(item);
                                      let c = [b[2], b[1], b[0]];
                                      a.push(c);
                                    });
                                    return {
                                      ...this.state,
                                      formMui: a,
                                    };
                                  },
                                  () => {
                                    localStorage.setItem(
                                      "formMui",
                                      JSON.stringify(this.state.formMui)
                                    );
                                    localStorage.setItem(
                                      "formList",
                                      JSON.stringify(this.state.formList)
                                    );
                                  }
                                );
                              }
                            );
                            this.setDefaultState();
                          }
                        })
                        .catch((err) => {
                          console.log("USER DUPLICATE RECORD ERR ... ", err);
                        });
                    }
                  })
                  .catch((err) => {
                    this.setState({ loader: false });
                    console.log(err);
                    obj.createNotification("error", err.message);
                  });
              } else if (
                this.state.selectedSubCat.label ==
                "Work Experience (Engineering related work including relevant aspects of Management)"
              ) {
                workExperienceSchema
                  .validate(this.state.formData)
                  .then((validation) => {
                    if (
                      this.state.formData.org_type === undefined ||
                      this.state.formData.org_type === ""
                    ) {
                      this.setState({ loader: false });
                      obj.createNotification(
                        "error",
                        "Organization Type is Required"
                      );
                    } else {
                      const data = {
                        file_claim: JSON.stringify(this.state.imageFile),
                        file_claim1: this.state.imageFile,
                        form_body: validation,
                        UserID: this.state.UserID,
                        form_id: this.state.formID,
                        fildsSet: this.state.fildsSet,
                      };

                      this.props
                        .getUserDuplicateRecords(data)
                        .then((res) => {
                          console.log("DUPLICATE RECORD RESSSSS ... ", res);
                          const { isDuplicate } = res.data;
                          if (!!isDuplicate) {
                            obj.createNotification(
                              "error",
                              "You have already submitted this claim"
                            );
                          } else {
                            this.setState(
                              () => {
                                return {
                                  ...this.state,
                                  globalForms: this.state.globalForms.concat({
                                    file_claim: JSON.stringify(
                                      this.state.imageFile
                                    ),
                                    file_claim1: this.state.imageFile,
                                    form_body: validation,
                                    UserID: this.state.UserID,
                                    form_id: this.state.formID,
                                    fildsSet: this.state.fildsSet,
                                  }),
                                };
                              },
                              () => {
                                localStorage.setItem(
                                  "globalForms",
                                  JSON.stringify(this.state.globalForms)
                                );
                              }
                            );

                            this.setState(
                              () => {
                                return {
                                  ...this.state,
                                  formList: this.state.formList.concat(
                                    this.state.formData
                                  ),
                                };
                              },
                              () => {
                                this.setState(
                                  () => {
                                    let a = [];
                                    this.state.formList.map((item) => {
                                      let b = Object.values(item);
                                      let c = [b[2], b[1], b[0]];
                                      a.push(c);
                                    });
                                    return {
                                      ...this.state,
                                      formMui: a,
                                    };
                                  },
                                  () => {
                                    localStorage.setItem(
                                      "formMui",
                                      JSON.stringify(this.state.formMui)
                                    );
                                    localStorage.setItem(
                                      "formList",
                                      JSON.stringify(this.state.formList)
                                    );
                                  }
                                );
                              }
                            );
                            this.setDefaultState();
                          }
                        })
                        .catch((err) => {
                          console.log("USER DUPLICATE RECORD ERR ... ", err);
                        });
                    }
                  })
                  .catch((err) => {
                    this.setState({ loader: false });
                    console.log(err);
                    obj.createNotification("error", err.message);
                  });
              } else if (
                this.state.selectedSubCat.label ==
                "Membership of a recognized professional  association/ body"
              ) {
                membershipOfRecognizedSchema
                  .validate(this.state.formData)
                  .then((validation) => {
                    if (
                      this.state.formData.membership_type === undefined ||
                      this.state.formData.membership_type === ""
                    ) {
                      this.setState({ loader: false });
                      obj.createNotification(
                        "error",
                        "Membership Type is Required"
                      );
                    } else if (
                      this.state.formData.professional_body_type ===
                        undefined ||
                      this.state.formData.professional_body_type === ""
                    ) {
                      this.setState({ loader: false });
                      obj.createNotification(
                        "error",
                        "Professional Body Type is Required"
                      );
                    } else {
                      const data = {
                        file_claim: JSON.stringify(this.state.imageFile),
                        file_claim1: this.state.imageFile,
                        form_body: validation,
                        UserID: this.state.UserID,
                        form_id: this.state.formID,
                        fildsSet: this.state.fildsSet,
                      };

                      this.props
                        .getUserDuplicateRecords(data)
                        .then((res) => {
                          console.log("DUPLICATE RECORD RESSSSS ... ", res);
                          const { isDuplicate } = res.data;
                          if (!!isDuplicate) {
                            obj.createNotification(
                              "error",
                              "You have already submitted this claim"
                            );
                          } else {
                            this.setState(
                              () => {
                                return {
                                  ...this.state,
                                  globalForms: this.state.globalForms.concat({
                                    file_claim: JSON.stringify(
                                      this.state.imageFile
                                    ),
                                    file_claim1: this.state.imageFile,
                                    form_body: validation,
                                    UserID: this.state.UserID,
                                    form_id: this.state.formID,
                                    fildsSet: this.state.fildsSet,
                                  }),
                                };
                              },
                              () => {
                                localStorage.setItem(
                                  "globalForms",
                                  JSON.stringify(this.state.globalForms)
                                );
                              }
                            );

                            this.setState(
                              () => {
                                return {
                                  ...this.state,
                                  formList: this.state.formList.concat(
                                    this.state.formData
                                  ),
                                };
                              },
                              () => {
                                this.setState(
                                  () => {
                                    let a = [];
                                    this.state.formList.map((item) => {
                                      let b = Object.values(item);
                                      let c = [b[2], b[1], b[0]];
                                      a.push(c);
                                    });
                                    return {
                                      ...this.state,
                                      formMui: a,
                                    };
                                  },
                                  () => {
                                    localStorage.setItem(
                                      "formMui",
                                      JSON.stringify(this.state.formMui)
                                    );
                                    localStorage.setItem(
                                      "formList",
                                      JSON.stringify(this.state.formList)
                                    );
                                  }
                                );
                              }
                            );
                            this.setDefaultState();
                          }
                        })
                        .catch((err) => {
                          console.log("USER DUPLICATE RECORD ERR ... ", err);
                        });
                    }
                  })
                  .catch((err) => {
                    this.setState({ loader: false });
                    console.log(err);
                    obj.createNotification("error", err.message);
                  });
              } else if (
                this.state.selectedSubCat.label ==
                "Participation in  Training & Development programmes"
              ) {
                participationInTrainingSchema
                  .validate(this.state.formData)
                  .then((validation) => {
                    if (
                      this.state.formData.inst_type === undefined ||
                      this.state.formData.inst_type === ""
                    ) {
                      this.setState({ loader: false });
                      obj.createNotification(
                        "error",
                        "Instution Type is Required"
                      );
                    } else {
                      const data = {
                        file_claim: JSON.stringify(this.state.imageFile),
                        file_claim1: this.state.imageFile,
                        form_body: validation,
                        UserID: this.state.UserID,
                        form_id: this.state.formID,
                        fildsSet: this.state.fildsSet,
                      };

                      this.props
                        .getUserDuplicateRecords(data)
                        .then((res) => {
                          console.log("DUPLICATE RECORD RESSSSS ... ", res);
                          const { isDuplicate } = res.data;
                          if (!!isDuplicate) {
                            obj.createNotification(
                              "error",
                              "You have already submitted this claim"
                            );
                          } else {
                            this.setState(
                              () => {
                                return {
                                  ...this.state,
                                  globalForms: this.state.globalForms.concat({
                                    file_claim: JSON.stringify(
                                      this.state.imageFile
                                    ),
                                    file_claim1: this.state.imageFile,
                                    form_body: validation,
                                    UserID: this.state.UserID,
                                    form_id: this.state.formID,
                                    fildsSet: this.state.fildsSet,
                                  }),
                                };
                              },
                              () => {
                                localStorage.setItem(
                                  "globalForms",
                                  JSON.stringify(this.state.globalForms)
                                );
                              }
                            );

                            this.setState(
                              () => {
                                return {
                                  ...this.state,
                                  formList: this.state.formList.concat(
                                    this.state.formData
                                  ),
                                };
                              },
                              () => {
                                this.setState(
                                  () => {
                                    let a = [];
                                    this.state.formList.map((item) => {
                                      let b = Object.values(item);
                                      let c = [b[2], b[1], b[0]];
                                      a.push(c);
                                    });
                                    return {
                                      ...this.state,
                                      formMui: a,
                                    };
                                  },
                                  () => {
                                    localStorage.setItem(
                                      "formMui",
                                      JSON.stringify(this.state.formMui)
                                    );
                                    localStorage.setItem(
                                      "formList",
                                      JSON.stringify(this.state.formList)
                                    );
                                  }
                                );
                              }
                            );
                            this.setDefaultState();
                          }
                        })
                        .catch((err) => {
                          console.log("USER DUPLICATE RECORD ERR ... ", err);
                        });
                    }
                  })
                  .catch((err) => {
                    this.setState({ loader: false });
                    console.log(err);
                    obj.createNotification("error", err.message);
                  });
              } else if (
                this.state.selectedSubCat.label ==
                "Participation in  Conference"
              ) {
                participationInConferenceSchema
                  .validate(this.state.formData)
                  .then((validation) => {
                    if (
                      this.state.formData.inst_type === undefined ||
                      this.state.formData.inst_type === ""
                    ) {
                      this.setState({ loader: false });
                      obj.createNotification(
                        "error",
                        "Instution Type is Required"
                      );
                    } else {
                      const data = {
                        file_claim: JSON.stringify(this.state.imageFile),
                        file_claim1: this.state.imageFile,
                        form_body: validation,
                        UserID: this.state.UserID,
                        form_id: this.state.formID,
                        fildsSet: this.state.fildsSet,
                      };

                      this.props
                        .getUserDuplicateRecords(data)
                        .then((res) => {
                          console.log("DUPLICATE RECORD RESSSSS ... ", res);
                          const { isDuplicate } = res.data;
                          if (!!isDuplicate) {
                            obj.createNotification(
                              "error",
                              "You have already submitted this claim"
                            );
                          } else {
                            this.setState(
                              () => {
                                return {
                                  ...this.state,
                                  globalForms: this.state.globalForms.concat({
                                    file_claim: JSON.stringify(
                                      this.state.imageFile
                                    ),
                                    file_claim1: this.state.imageFile,
                                    form_body: validation,
                                    UserID: this.state.UserID,
                                    form_id: this.state.formID,
                                    fildsSet: this.state.fildsSet,
                                  }),
                                };
                              },
                              () => {
                                localStorage.setItem(
                                  "globalForms",
                                  JSON.stringify(this.state.globalForms)
                                );
                              }
                            );

                            this.setState(
                              () => {
                                return {
                                  ...this.state,
                                  formList: this.state.formList.concat(
                                    this.state.formData
                                  ),
                                };
                              },
                              () => {
                                this.setState(
                                  () => {
                                    let a = [];
                                    this.state.formList.map((item) => {
                                      let b = Object.values(item);
                                      let c = [b[2], b[1], b[0]];
                                      a.push(c);
                                    });
                                    return {
                                      ...this.state,
                                      formMui: a,
                                    };
                                  },
                                  () => {
                                    localStorage.setItem(
                                      "formMui",
                                      JSON.stringify(this.state.formMui)
                                    );
                                    localStorage.setItem(
                                      "formList",
                                      JSON.stringify(this.state.formList)
                                    );
                                  }
                                );
                              }
                            );
                            this.setDefaultState();
                          }
                        })
                        .catch((err) => {
                          console.log("USER DUPLICATE RECORD ERR ... ", err);
                        });
                    }
                  })
                  .catch((err) => {
                    this.setState({ loader: false });
                    console.log(err);
                    obj.createNotification("error", err.message);
                  });
              } else if (
                this.state.selectedSubCat.label == "Participation in Seminar"
              ) {
                participationInSeminarSchema
                  .validate(this.state.formData)
                  .then((validation) => {
                    if (
                      this.state.formData.inst_type === undefined ||
                      this.state.formData.inst_type === ""
                    ) {
                      this.setState({ loader: false });
                      obj.createNotification(
                        "error",
                        "Instution Type is Required"
                      );
                    } else {
                      const data = {
                        file_claim: JSON.stringify(this.state.imageFile),
                        file_claim1: this.state.imageFile,
                        form_body: validation,
                        UserID: this.state.UserID,
                        form_id: this.state.formID,
                        fildsSet: this.state.fildsSet,
                      };

                      this.props
                        .getUserDuplicateRecords(data)
                        .then((res) => {
                          console.log("DUPLICATE RECORD RESSSSS ... ", res);
                          const { isDuplicate } = res.data;
                          if (!!isDuplicate) {
                            obj.createNotification(
                              "error",
                              "You have already submitted this claim"
                            );
                          } else {
                            this.setState(
                              () => {
                                return {
                                  ...this.state,
                                  globalForms: this.state.globalForms.concat({
                                    file_claim: JSON.stringify(
                                      this.state.imageFile
                                    ),
                                    file_claim1: this.state.imageFile,
                                    form_body: validation,
                                    UserID: this.state.UserID,
                                    form_id: this.state.formID,
                                    fildsSet: this.state.fildsSet,
                                  }),
                                };
                              },
                              () => {
                                localStorage.setItem(
                                  "globalForms",
                                  JSON.stringify(this.state.globalForms)
                                );
                              }
                            );

                            this.setState(
                              () => {
                                return {
                                  ...this.state,
                                  formList: this.state.formList.concat(
                                    this.state.formData
                                  ),
                                };
                              },
                              () => {
                                this.setState(
                                  () => {
                                    let a = [];
                                    this.state.formList.map((item) => {
                                      let b = Object.values(item);
                                      let c = [b[2], b[1], b[0]];
                                      a.push(c);
                                    });
                                    return {
                                      ...this.state,
                                      formMui: a,
                                    };
                                  },
                                  () => {
                                    localStorage.setItem(
                                      "formMui",
                                      JSON.stringify(this.state.formMui)
                                    );
                                    localStorage.setItem(
                                      "formList",
                                      JSON.stringify(this.state.formList)
                                    );
                                  }
                                );
                              }
                            );
                            this.setDefaultState();
                          }
                        })
                        .catch((err) => {
                          console.log("USER DUPLICATE RECORD ERR ... ", err);
                        });
                    }
                  })
                  .catch((err) => {
                    this.setState({ loader: false });
                    console.log(err);
                    obj.createNotification("error", err.message);
                  });
              } else if (
                this.state.selectedSubCat.label == "Participation in Lectures"
              ) {
                participationInLecturesSchema
                  .validate(this.state.formData)
                  .then((validation) => {
                    if (
                      this.state.formData.inst_type === undefined ||
                      this.state.formData.inst_type === ""
                    ) {
                      this.setState({ loader: false });
                      obj.createNotification(
                        "error",
                        "Instution Type is Required"
                      );
                    }

                    const data = {
                      file_claim: JSON.stringify(this.state.imageFile),
                      file_claim1: this.state.imageFile,
                      form_body: validation,
                      UserID: this.state.UserID,
                      form_id: this.state.formID,
                      fildsSet: this.state.fildsSet,
                    };

                    this.props
                      .getUserDuplicateRecords(data)
                      .then((res) => {
                        console.log("DUPLICATE RECORD RESSSSS ... ", res);
                        const { isDuplicate } = res.data;
                        if (!!isDuplicate) {
                          obj.createNotification(
                            "error",
                            "You have already submitted this claim"
                          );
                        } else {
                          this.setState(
                            () => {
                              return {
                                ...this.state,
                                globalForms: this.state.globalForms.concat({
                                  file_claim: JSON.stringify(
                                    this.state.imageFile
                                  ),
                                  file_claim1: this.state.imageFile,
                                  form_body: validation,
                                  UserID: this.state.UserID,
                                  form_id: this.state.formID,
                                  fildsSet: this.state.fildsSet,
                                }),
                              };
                            },
                            () => {
                              localStorage.setItem(
                                "globalForms",
                                JSON.stringify(this.state.globalForms)
                              );
                            }
                          );

                          this.setState(
                            () => {
                              return {
                                ...this.state,
                                formList: this.state.formList.concat(
                                  this.state.formData
                                ),
                              };
                            },
                            () => {
                              this.setState(
                                () => {
                                  let a = [];
                                  this.state.formList.map((item) => {
                                    let b = Object.values(item);
                                    let c = [b[2], b[1], b[0]];
                                    a.push(c);
                                  });
                                  return {
                                    ...this.state,
                                    formMui: a,
                                  };
                                },
                                () => {
                                  localStorage.setItem(
                                    "formMui",
                                    JSON.stringify(this.state.formMui)
                                  );
                                  localStorage.setItem(
                                    "formList",
                                    JSON.stringify(this.state.formList)
                                  );
                                }
                              );
                            }
                          );
                          this.setDefaultState();
                        }
                      })
                      .catch((err) => {
                        console.log("USER DUPLICATE RECORD ERR ... ", err);
                      });
                  })
                  .catch((err) => {
                    this.setState({ loader: false });
                    console.log(err);
                    obj.createNotification("error", err.message);
                  });
              } else if (
                this.state.selectedSubCat.label == "Participation in Workshop"
              ) {
                participationInWorkshopSchema
                  .validate(this.state.formData)
                  .then((validation) => {
                    if (
                      this.state.formData.inst_type === undefined ||
                      this.state.formData.inst_type === ""
                    ) {
                      this.setState({ loader: false });
                      obj.createNotification(
                        "error",
                        "Instution Type is Required"
                      );
                    }

                    const data = {
                      file_claim: JSON.stringify(this.state.imageFile),
                      file_claim1: this.state.imageFile,
                      form_body: validation,
                      UserID: this.state.UserID,
                      form_id: this.state.formID,
                      fildsSet: this.state.fildsSet,
                    };

                    this.props
                      .getUserDuplicateRecords(data)
                      .then((res) => {
                        console.log("DUPLICATE RECORD RESSSSS ... ", res);
                        const { isDuplicate } = res.data;
                        if (!!isDuplicate) {
                          obj.createNotification(
                            "error",
                            "You have already submitted this claim"
                          );
                        } else {
                          this.setState(
                            () => {
                              return {
                                ...this.state,
                                globalForms: this.state.globalForms.concat({
                                  file_claim: JSON.stringify(
                                    this.state.imageFile
                                  ),
                                  file_claim1: this.state.imageFile,
                                  form_body: validation,
                                  UserID: this.state.UserID,
                                  form_id: this.state.formID,
                                  fildsSet: this.state.fildsSet,
                                }),
                              };
                            },
                            () => {
                              localStorage.setItem(
                                "globalForms",
                                JSON.stringify(this.state.globalForms)
                              );
                            }
                          );

                          this.setState(
                            () => {
                              return {
                                ...this.state,
                                formList: this.state.formList.concat(
                                  this.state.formData
                                ),
                              };
                            },
                            () => {
                              this.setState(
                                () => {
                                  let a = [];
                                  this.state.formList.map((item) => {
                                    let b = Object.values(item);
                                    let c = [b[2], b[1], b[0]];
                                    a.push(c);
                                  });
                                  return {
                                    ...this.state,
                                    formMui: a,
                                  };
                                },
                                () => {
                                  localStorage.setItem(
                                    "formMui",
                                    JSON.stringify(this.state.formMui)
                                  );
                                  localStorage.setItem(
                                    "formList",
                                    JSON.stringify(this.state.formList)
                                  );
                                }
                              );
                            }
                          );
                          this.setDefaultState();
                        }
                      })
                      .catch((err) => {
                        console.log("USER DUPLICATE RECORD ERR ... ", err);
                      });
                  })
                  .catch((err) => {
                    this.setState({ loader: false });
                    console.log(err);
                    obj.createNotification("error", err.message);
                  });
              } else if (
                this.state.selectedSubCat.label ==
                "Refresher Course/ short course "
              ) {
                refresherCourseSchema
                  .validate(this.state.formData)
                  .then((validation) => {
                    if (
                      this.state.formData.inst_type === undefined ||
                      this.state.formData.inst_type === ""
                    ) {
                      this.setState({ loader: false });
                      obj.createNotification(
                        "error",
                        "Instution Type is Required"
                      );
                    }

                    const data = {
                      file_claim: JSON.stringify(this.state.imageFile),
                      file_claim1: this.state.imageFile,
                      form_body: validation,
                      UserID: this.state.UserID,
                      form_id: this.state.formID,
                      fildsSet: this.state.fildsSet,
                    };

                    this.props
                      .getUserDuplicateRecords(data)
                      .then((res) => {
                        console.log("DUPLICATE RECORD RESSSSS ... ", res);
                        const { isDuplicate } = res.data;
                        if (!!isDuplicate) {
                          obj.createNotification(
                            "error",
                            "You have already submitted this claim"
                          );
                        } else {
                          this.setState(
                            () => {
                              return {
                                ...this.state,
                                globalForms: this.state.globalForms.concat({
                                  file_claim: JSON.stringify(
                                    this.state.imageFile
                                  ),
                                  file_claim1: this.state.imageFile,
                                  form_body: validation,
                                  UserID: this.state.UserID,
                                  form_id: this.state.formID,
                                  fildsSet: this.state.fildsSet,
                                }),
                              };
                            },
                            () => {
                              localStorage.setItem(
                                "globalForms",
                                JSON.stringify(this.state.globalForms)
                              );
                            }
                          );

                          this.setState(
                            () => {
                              return {
                                ...this.state,
                                formList: this.state.formList.concat(
                                  this.state.formData
                                ),
                              };
                            },
                            () => {
                              this.setState(
                                () => {
                                  let a = [];
                                  this.state.formList.map((item) => {
                                    let b = Object.values(item);
                                    let c = [b[2], b[1], b[0]];
                                    a.push(c);
                                  });
                                  return {
                                    ...this.state,
                                    formMui: a,
                                  };
                                },
                                () => {
                                  localStorage.setItem(
                                    "formMui",
                                    JSON.stringify(this.state.formMui)
                                  );
                                  localStorage.setItem(
                                    "formList",
                                    JSON.stringify(this.state.formList)
                                  );
                                }
                              );
                            }
                          );
                          this.setDefaultState();
                        }
                      })
                      .catch((err) => {
                        console.log("USER DUPLICATE RECORD ERR ... ", err);
                      });
                  })
                  .catch((err) => {
                    this.setState({ loader: false });
                    console.log(err);
                    obj.createNotification("error", err.message);
                  });
              } else if (
                this.state.selectedSubCat.label == "Invited lecture "
              ) {
                invitedLectureSchema
                  .validate(this.state.formData)
                  .then((validation) => {
                    if (
                      this.state.formData.inst_type === undefined ||
                      this.state.formData.inst_type === ""
                    ) {
                      this.setState({ loader: false });
                      obj.createNotification(
                        "error",
                        "Instution Type is Required"
                      );
                    } else {
                      const data = {
                        file_claim: JSON.stringify(this.state.imageFile),
                        file_claim1: this.state.imageFile,
                        form_body: validation,
                        UserID: this.state.UserID,
                        form_id: this.state.formID,
                        fildsSet: this.state.fildsSet,
                      };

                      this.props
                        .getUserDuplicateRecords(data)
                        .then((res) => {
                          console.log("DUPLICATE RECORD RESSSSS ... ", res);
                          const { isDuplicate } = res.data;
                          if (!!isDuplicate) {
                            obj.createNotification(
                              "error",
                              "You have already submitted this claim"
                            );
                          } else {
                            this.setState(
                              () => {
                                return {
                                  ...this.state,
                                  globalForms: this.state.globalForms.concat({
                                    file_claim: JSON.stringify(
                                      this.state.imageFile
                                    ),
                                    file_claim1: this.state.imageFile,
                                    form_body: validation,
                                    UserID: this.state.UserID,
                                    form_id: this.state.formID,
                                    fildsSet: this.state.fildsSet,
                                  }),
                                };
                              },
                              () => {
                                localStorage.setItem(
                                  "globalForms",
                                  JSON.stringify(this.state.globalForms)
                                );
                              }
                            );

                            this.setState(
                              () => {
                                return {
                                  ...this.state,
                                  formList: this.state.formList.concat(
                                    this.state.formData
                                  ),
                                };
                              },
                              () => {
                                this.setState(
                                  () => {
                                    let a = [];
                                    this.state.formList.map((item) => {
                                      let b = Object.values(item);
                                      let c = [b[2], b[1], b[0]];
                                      a.push(c);
                                    });
                                    return {
                                      ...this.state,
                                      formMui: a,
                                    };
                                  },
                                  () => {
                                    localStorage.setItem(
                                      "formMui",
                                      JSON.stringify(this.state.formMui)
                                    );
                                    localStorage.setItem(
                                      "formList",
                                      JSON.stringify(this.state.formList)
                                    );
                                  }
                                );
                              }
                            );
                            this.setDefaultState();
                          }
                        })
                        .catch((err) => {
                          console.log("USER DUPLICATE RECORD ERR ... ", err);
                        });
                    }
                  })
                  .catch((err) => {
                    this.setState({ loader: false });
                    console.log(err);
                    obj.createNotification("error", err.message);
                  });
              } else if (
                this.state.selectedSubCat.label == "Symposium panelist"
              ) {
                symposiumSchema
                  .validate(this.state.formData)
                  .then((validation) => {
                    if (
                      this.state.formData.inst_type === undefined ||
                      this.state.formData.inst_type === ""
                    ) {
                      this.setState({ loader: false });
                      obj.createNotification(
                        "error",
                        "Instution Type is Required"
                      );
                    } else {
                      const data = {
                        file_claim: JSON.stringify(this.state.imageFile),
                        file_claim1: this.state.imageFile,
                        form_body: validation,
                        UserID: this.state.UserID,
                        form_id: this.state.formID,
                        fildsSet: this.state.fildsSet,
                      };

                      this.props
                        .getUserDuplicateRecords(data)
                        .then((res) => {
                          console.log("DUPLICATE RECORD RESSSSS ... ", res);
                          const { isDuplicate } = res.data;
                          if (!!isDuplicate) {
                            obj.createNotification(
                              "error",
                              "You have already submitted this claim"
                            );
                          } else {
                            this.setState(
                              () => {
                                return {
                                  ...this.state,
                                  globalForms: this.state.globalForms.concat({
                                    file_claim: JSON.stringify(
                                      this.state.imageFile
                                    ),
                                    file_claim1: this.state.imageFile,
                                    form_body: validation,
                                    UserID: this.state.UserID,
                                    form_id: this.state.formID,
                                    fildsSet: this.state.fildsSet,
                                  }),
                                };
                              },
                              () => {
                                localStorage.setItem(
                                  "globalForms",
                                  JSON.stringify(this.state.globalForms)
                                );
                              }
                            );

                            this.setState(
                              () => {
                                return {
                                  ...this.state,
                                  formList: this.state.formList.concat(
                                    this.state.formData
                                  ),
                                };
                              },
                              () => {
                                this.setState(
                                  () => {
                                    let a = [];
                                    this.state.formList.map((item) => {
                                      let b = Object.values(item);
                                      let c = [b[2], b[1], b[0]];
                                      a.push(c);
                                    });
                                    return {
                                      ...this.state,
                                      formMui: a,
                                    };
                                  },
                                  () => {
                                    localStorage.setItem(
                                      "formMui",
                                      JSON.stringify(this.state.formMui)
                                    );
                                    localStorage.setItem(
                                      "formList",
                                      JSON.stringify(this.state.formList)
                                    );
                                  }
                                );
                              }
                            );
                            this.setDefaultState();
                          }
                        })
                        .catch((err) => {
                          console.log("USER DUPLICATE RECORD ERR ... ", err);
                        });
                    }
                  })
                  .catch((err) => {
                    this.setState({ loader: false });
                    console.log(err);
                    obj.createNotification("error", err.message);
                  });
              } else if (
                this.state.selectedSubCat.label ==
                "Writing and publishing a research article in Journals/Technical report"
              ) {
                writingAndPublishingSchema
                  .validate(this.state.formData)
                  .then((validation) => {
                    if (
                      this.state.formData.publication_type === undefined ||
                      this.state.formData.publication_type === ""
                    ) {
                      this.setState({ loader: false });
                      obj.createNotification(
                        "error",
                        "Publication Type is Required"
                      );
                    } else {
                      const data = {
                        file_claim: JSON.stringify(this.state.imageFile),
                        file_claim1: this.state.imageFile,
                        form_body: validation,
                        UserID: this.state.UserID,
                        form_id: this.state.formID,
                        fildsSet: this.state.fildsSet,
                      };

                      this.props
                        .getUserDuplicateRecords(data)
                        .then((res) => {
                          console.log("DUPLICATE RECORD RESSSSS ... ", res);
                          const { isDuplicate } = res.data;
                          if (!!isDuplicate) {
                            obj.createNotification(
                              "error",
                              "You have already submitted this claim"
                            );
                          } else {
                            this.setState(
                              () => {
                                return {
                                  ...this.state,
                                  globalForms: this.state.globalForms.concat({
                                    file_claim: JSON.stringify(
                                      this.state.imageFile
                                    ),
                                    file_claim1: this.state.imageFile,
                                    form_body: validation,
                                    UserID: this.state.UserID,
                                    form_id: this.state.formID,
                                    fildsSet: this.state.fildsSet,
                                  }),
                                };
                              },
                              () => {
                                localStorage.setItem(
                                  "globalForms",
                                  JSON.stringify(this.state.globalForms)
                                );
                              }
                            );

                            this.setState(
                              () => {
                                return {
                                  ...this.state,
                                  formList: this.state.formList.concat(
                                    this.state.formData
                                  ),
                                };
                              },
                              () => {
                                this.setState(
                                  () => {
                                    let a = [];
                                    this.state.formList.map((item) => {
                                      let b = Object.values(item);
                                      let c = [b[2], b[1], b[0]];
                                      a.push(c);
                                    });
                                    return {
                                      ...this.state,
                                      formMui: a,
                                    };
                                  },
                                  () => {
                                    localStorage.setItem(
                                      "formMui",
                                      JSON.stringify(this.state.formMui)
                                    );
                                    localStorage.setItem(
                                      "formList",
                                      JSON.stringify(this.state.formList)
                                    );
                                  }
                                );
                              }
                            );
                            this.setDefaultState();
                          }
                        })
                        .catch((err) => {
                          console.log("USER DUPLICATE RECORD ERR ... ", err);
                        });
                    }
                  })
                  .catch((err) => {
                    this.setState({ loader: false });
                    console.log(err);
                    obj.createNotification("error", err.message);
                  });
              } else if (
                this.state.selectedSubCat.label == "Writing Conference paper"
              ) {
                writingConferencePaperSchema
                  .validate(this.state.formData)
                  .then((validation) => {
                    if (
                      this.state.formData.paper_type === undefined ||
                      this.state.formData.paper_type === ""
                    ) {
                      this.setState({ loader: false });
                      obj.createNotification("error", "Paper Type is Required");
                    } else {
                      const data = {
                        file_claim: JSON.stringify(this.state.imageFile),
                        file_claim1: this.state.imageFile,
                        form_body: validation,
                        UserID: this.state.UserID,
                        form_id: this.state.formID,
                        fildsSet: this.state.fildsSet,
                      };

                      this.props
                        .getUserDuplicateRecords(data)
                        .then((res) => {
                          console.log("DUPLICATE RECORD RESSSSS ... ", res);
                          const { isDuplicate } = res.data;
                          if (!!isDuplicate) {
                            obj.createNotification(
                              "error",
                              "You have already submitted this claim"
                            );
                          } else {
                            this.setState(
                              () => {
                                return {
                                  ...this.state,
                                  globalForms: this.state.globalForms.concat({
                                    file_claim: JSON.stringify(
                                      this.state.imageFile
                                    ),
                                    file_claim1: this.state.imageFile,
                                    form_body: validation,
                                    UserID: this.state.UserID,
                                    form_id: this.state.formID,
                                    fildsSet: this.state.fildsSet,
                                  }),
                                };
                              },
                              () => {
                                localStorage.setItem(
                                  "globalForms",
                                  JSON.stringify(this.state.globalForms)
                                );
                              }
                            );

                            this.setState(
                              () => {
                                return {
                                  ...this.state,
                                  formList: this.state.formList.concat(
                                    this.state.formData
                                  ),
                                };
                              },
                              () => {
                                this.setState(
                                  () => {
                                    let a = [];
                                    this.state.formList.map((item) => {
                                      let b = Object.values(item);
                                      let c = [b[2], b[1], b[0]];
                                      a.push(c);
                                    });
                                    return {
                                      ...this.state,
                                      formMui: a,
                                    };
                                  },
                                  () => {
                                    localStorage.setItem(
                                      "formMui",
                                      JSON.stringify(this.state.formMui)
                                    );
                                    localStorage.setItem(
                                      "formList",
                                      JSON.stringify(this.state.formList)
                                    );
                                  }
                                );
                              }
                            );
                            this.setDefaultState();
                          }
                        })
                        .catch((err) => {
                          console.log("USER DUPLICATE RECORD ERR ... ", err);
                        });
                    }
                  })
                  .catch((err) => {
                    this.setState({ loader: false });
                    console.log(err);
                    obj.createNotification("error", err.message);
                  });
              } else if (
                this.state.selectedSubCat.label == "Authoring a Book"
              ) {
                authoringBookSchema
                  .validate(this.state.formData)
                  .then((validation) => {
                    const data = {
                      file_claim: JSON.stringify(this.state.imageFile),
                      file_claim1: this.state.imageFile,
                      form_body: validation,
                      UserID: this.state.UserID,
                      form_id: this.state.formID,
                      fildsSet: this.state.fildsSet,
                    };

                    this.props
                      .getUserDuplicateRecords(data)
                      .then((res) => {
                        console.log("DUPLICATE RECORD RESSSSS ... ", res);
                        const { isDuplicate } = res.data;
                        if (!!isDuplicate) {
                          obj.createNotification(
                            "error",
                            "You have already submitted this claim"
                          );
                        } else {
                          this.setState(
                            () => {
                              return {
                                ...this.state,
                                globalForms: this.state.globalForms.concat({
                                  file_claim: JSON.stringify(
                                    this.state.imageFile
                                  ),
                                  file_claim1: this.state.imageFile,
                                  form_body: validation,
                                  UserID: this.state.UserID,
                                  form_id: this.state.formID,
                                  fildsSet: this.state.fildsSet,
                                }),
                              };
                            },
                            () => {
                              localStorage.setItem(
                                "globalForms",
                                JSON.stringify(this.state.globalForms)
                              );
                            }
                          );

                          this.setState(
                            () => {
                              return {
                                ...this.state,
                                formList: this.state.formList.concat(
                                  this.state.formData
                                ),
                              };
                            },
                            () => {
                              this.setState(
                                () => {
                                  let a = [];
                                  this.state.formList.map((item) => {
                                    let b = Object.values(item);
                                    let c = [b[2], b[1], b[0]];
                                    a.push(c);
                                  });
                                  return {
                                    ...this.state,
                                    formMui: a,
                                  };
                                },
                                () => {
                                  localStorage.setItem(
                                    "formMui",
                                    JSON.stringify(this.state.formMui)
                                  );
                                  localStorage.setItem(
                                    "formList",
                                    JSON.stringify(this.state.formList)
                                  );
                                }
                              );
                            }
                          );
                          this.setDefaultState();
                        }
                      })
                      .catch((err) => {
                        console.log("USER DUPLICATE RECORD ERR ... ", err);
                      });
                  })
                  .catch((err) => {
                    this.setState({ loader: false });
                    console.log(err);
                    obj.createNotification("error", err.message);
                  });
              } else if (
                this.state.selectedSubCat.label ==
                "Part of activities such as emergency/ recognized social work"
              ) {
                partOfActivitiesSchema
                  .validate(this.state.formData)
                  .then((validation) => {
                    if (
                      this.state.formData.organizing_body === undefined ||
                      this.state.formData.organizing_body === ""
                    ) {
                      this.setState({ loader: false });
                      obj.createNotification("error", "Paper Type is Required");
                    } else {
                      const data = {
                        file_claim: JSON.stringify(this.state.imageFile),
                        file_claim1: this.state.imageFile,
                        form_body: validation,
                        UserID: this.state.UserID,
                        form_id: this.state.formID,
                        fildsSet: this.state.fildsSet,
                      };

                      this.props
                        .getUserDuplicateRecords(data)
                        .then((res) => {
                          console.log("DUPLICATE RECORD RESSSSS ... ", res);
                          const { isDuplicate } = res.data;
                          if (!!isDuplicate) {
                            obj.createNotification(
                              "error",
                              "You have already submitted this claim"
                            );
                          } else {
                            this.setState(
                              () => {
                                return {
                                  ...this.state,
                                  globalForms: this.state.globalForms.concat({
                                    file_claim: JSON.stringify(
                                      this.state.imageFile
                                    ),
                                    file_claim1: this.state.imageFile,
                                    form_body: validation,
                                    UserID: this.state.UserID,
                                    form_id: this.state.formID,
                                    fildsSet: this.state.fildsSet,
                                  }),
                                };
                              },
                              () => {
                                localStorage.setItem(
                                  "globalForms",
                                  JSON.stringify(this.state.globalForms)
                                );
                              }
                            );

                            this.setState(
                              () => {
                                return {
                                  ...this.state,
                                  formList: this.state.formList.concat(
                                    this.state.formData
                                  ),
                                };
                              },
                              () => {
                                this.setState(
                                  () => {
                                    let a = [];
                                    this.state.formList.map((item) => {
                                      let b = Object.values(item);
                                      let c = [b[2], b[1], b[0]];
                                      a.push(c);
                                    });
                                    return {
                                      ...this.state,
                                      formMui: a,
                                    };
                                  },
                                  () => {
                                    localStorage.setItem(
                                      "formMui",
                                      JSON.stringify(this.state.formMui)
                                    );
                                    localStorage.setItem(
                                      "formList",
                                      JSON.stringify(this.state.formList)
                                    );
                                  }
                                );
                              }
                            );
                            this.setDefaultState();
                          }
                        })
                        .catch((err) => {
                          console.log("USER DUPLICATE RECORD ERR ... ", err);
                        });
                    }
                  })
                  .catch((err) => {
                    this.setState({ loader: false });
                    console.log(err);
                    obj.createNotification("error", err.message);
                  });
              } else if (
                this.state.selectedSubCat.label ==
                "Participation in statutory. Technical or non-technical committees"
              ) {
                participationInStatutorySchema
                  .validate(this.state.formData)
                  .then((validation) => {
                    const data = {
                      file_claim: JSON.stringify(this.state.imageFile),
                      file_claim1: this.state.imageFile,
                      form_body: validation,
                      UserID: this.state.UserID,
                      form_id: this.state.formID,
                      fildsSet: this.state.fildsSet,
                    };

                    this.props
                      .getUserDuplicateRecords(data)
                      .then((res) => {
                        console.log("DUPLICATE RECORD RESSSSS ... ", res);
                        const { isDuplicate } = res.data;
                        if (!!isDuplicate) {
                          obj.createNotification(
                            "error",
                            "You have already submitted this claim"
                          );
                        } else {
                          this.setState(
                            () => {
                              return {
                                ...this.state,
                                globalForms: this.state.globalForms.concat({
                                  file_claim: JSON.stringify(
                                    this.state.imageFile
                                  ),
                                  file_claim1: this.state.imageFile,
                                  form_body: validation,
                                  UserID: this.state.UserID,
                                  form_id: this.state.formID,
                                  fildsSet: this.state.fildsSet,
                                }),
                              };
                            },
                            () => {
                              localStorage.setItem(
                                "globalForms",
                                JSON.stringify(this.state.globalForms)
                              );
                            }
                          );

                          this.setState(
                            () => {
                              return {
                                ...this.state,
                                formList: this.state.formList.concat(
                                  this.state.formData
                                ),
                              };
                            },
                            () => {
                              this.setState(
                                () => {
                                  let a = [];
                                  this.state.formList.map((item) => {
                                    let b = Object.values(item);
                                    let c = [b[2], b[1], b[0]];
                                    a.push(c);
                                  });
                                  return {
                                    ...this.state,
                                    formMui: a,
                                  };
                                },
                                () => {
                                  localStorage.setItem(
                                    "formMui",
                                    JSON.stringify(this.state.formMui)
                                  );
                                  localStorage.setItem(
                                    "formList",
                                    JSON.stringify(this.state.formList)
                                  );
                                }
                              );
                            }
                          );
                          this.setDefaultState();
                        }
                      })
                      .catch((err) => {
                        console.log("USER DUPLICATE RECORD ERR ... ", err);
                      });
                  })
                  .catch((err) => {
                    this.setState({ loader: false });
                    console.log(err);
                    obj.createNotification("error", err.message);
                  });
              } else if (
                this.state.selectedSubCat.label == "Managing Technical event"
              ) {
                managingTechnicalSchema
                  .validate(this.state.formData)
                  .then((validation) => {
                    if (
                      this.state.formData.event_type === undefined ||
                      this.state.formData.event_type === ""
                    ) {
                      this.setState({ loader: false });
                      obj.createNotification("error", "Event Type is Required");
                    } else {
                      const data = {
                        file_claim: JSON.stringify(this.state.imageFile),
                        file_claim1: this.state.imageFile,
                        form_body: validation,
                        UserID: this.state.UserID,
                        form_id: this.state.formID,
                        fildsSet: this.state.fildsSet,
                      };

                      this.props
                        .getUserDuplicateRecords(data)
                        .then((res) => {
                          console.log("DUPLICATE RECORD RESSSSS ... ", res);
                          const { isDuplicate } = res.data;
                          if (!!isDuplicate) {
                            obj.createNotification(
                              "error",
                              "You have already submitted this claim"
                            );
                          } else {
                            this.setState(
                              () => {
                                return {
                                  ...this.state,
                                  globalForms: this.state.globalForms.concat({
                                    file_claim: JSON.stringify(
                                      this.state.imageFile
                                    ),
                                    file_claim1: this.state.imageFile,
                                    form_body: validation,
                                    UserID: this.state.UserID,
                                    form_id: this.state.formID,
                                    fildsSet: this.state.fildsSet,
                                  }),
                                };
                              },
                              () => {
                                localStorage.setItem(
                                  "globalForms",
                                  JSON.stringify(this.state.globalForms)
                                );
                              }
                            );

                            this.setState(
                              () => {
                                return {
                                  ...this.state,
                                  formList: this.state.formList.concat(
                                    this.state.formData
                                  ),
                                };
                              },
                              () => {
                                this.setState(
                                  () => {
                                    let a = [];
                                    this.state.formList.map((item) => {
                                      let b = Object.values(item);
                                      let c = [b[2], b[1], b[0]];
                                      a.push(c);
                                    });
                                    return {
                                      ...this.state,
                                      formMui: a,
                                    };
                                  },
                                  () => {
                                    localStorage.setItem(
                                      "formMui",
                                      JSON.stringify(this.state.formMui)
                                    );
                                    localStorage.setItem(
                                      "formList",
                                      JSON.stringify(this.state.formList)
                                    );
                                  }
                                );
                              }
                            );
                            this.setDefaultState();
                          }
                        })
                        .catch((err) => {
                          console.log("USER DUPLICATE RECORD ERR ... ", err);
                        });
                    }
                  })
                  .catch((err) => {
                    this.setState({ loader: false });
                    console.log(err);
                    obj.createNotification("error", err.message);
                  });
              }
              // }
            })
            .catch((err) => {
              this.setState({ loader: false });
              console.log(err);
            });
        }
      }
    });
  };

  handlePageRefresh = (event) => {
    // Perform your actions here when the page is refreshed
    localStorage.removeItem("globalForms");
    localStorage.removeItem("formMui");
    localStorage.removeItem("formList");
  };

  componentDidMount() {
    window.addEventListener("beforeunload", this.handlePageRefresh);
    let a = [];
    let b = [];
    let c = [];
    window.scrollTo(0, 0);
    if (this.state.shouldBlockNavigation) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = undefined;
    }
    if (this.props.User) {
      this.setState({ UserID: this.props.User.UserID });
    }
    if (this.props.location.state && this.props.location.state.cat) {
      this.setState(
        {
          // selectedCat: {
          //   value: this.props.location.state.cat,
          //   label: this.props.location.state.cat,
          // },
          // selectedSubCat: {
          //   value: this.props.location.state.sub,
          //   label: this.props.location.state.sub,
          // },

          formData: {
            ...this.state.formData,
            select_category: this.props.location.state.cat,
            sub_category: this.props.location.state.sub,
          },
        },
        () => {
          this.props
            .getSubCategories(this.state.selectedCat.value)
            .then((res) => {
              let subcategoriesSet = [];
              for (let i = 0; i < res.length; i++) {
                subcategoriesSet.push({
                  id: res[i].cpd_points_id,
                  category: res[i].subcategory,
                });
              }
              let subcategoryy = [];
              let subcat1Set = [];
              subcategoriesSet.map((item, index) => {
                subcat1Set.push({
                  label: item.category,
                  value: item.category,
                });
                subcategoryy[index] = {
                  [item]: res.filter((obj) => {
                    return obj.subcategory === item;
                  }),
                };
              });
              this.setState(
                {
                  subcat1Set: subcat1Set,
                  subcategoriesSet: subcategoriesSet,
                },
                () => {
                  this.handleSubCategory2(this.props.location.state.sub);
                }
              );
            })
            .catch((err) => {
              console.log(err);
            });
        }
      );
    }
    this.props
      .getFields()
      .then((res) => {
        console.log(res);
        let categoriesSet = [];
        for (let i = 0; i < res.length; i++) {
          categoriesSet.push(res[i].category);
        }
        const alphabet = [
          "a",
          "b",
          "c",
          "d",
          "e",
          "f",
          "g",
          "h",
          "i",
          "j",
          "k",
          "l",
          "m",
          "n",
          "o",
          "p",
          "q",
          "r",
          "s",
          "t",
          "u",
          "v",
          "w",
          "x",
          "y",
          "z",
        ];
        let uniquecat = [...new Set(categoriesSet)];
        let a = [];
        uniquecat.map((item, index) => {
          a.push(alphabet[index] + ". " + item);
        });
        let categoryy = [];
        let cat1Set = [];
        uniquecat.map((item, index) => {
          cat1Set.push({ value: item, label: a[index] });
          categoryy[index] = {
            [item]: res.filter((obj) => {
              return obj.category === item;
            }),
          };
        });
        this.setState({ cat1Set: cat1Set });
      })
      .catch((err) => console.log(err));
    if (JSON.parse(localStorage.getItem("formMui")) !== undefined) {
      a = JSON.parse(localStorage.getItem("formMui"));
      console.log("printing a ", a);
      if (a === null) {
        this.setState({ formMui: [] });
      } else {
        this.setState({ formMui: a });
      }
    }

    if (JSON.parse(localStorage.getItem("formList")) !== undefined) {
      b = JSON.parse(localStorage.getItem("formList"));
      console.log("printing b ", b);
      if (b === null) {
        this.setState({ formList: [] });
      } else {
        this.setState({ formList: b });
      }
    }
    if (JSON.parse(localStorage.getItem("globalForms")) !== undefined) {
      c = JSON.parse(localStorage.getItem("globalForms"));
      console.log("printing c ", c);
      if (c === null) {
        this.setState({ globalForms: [] });
      } else {
        this.setState({ globalForms: c });
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.handlePageRefresh);
  }

  handleOnDropImage = (files, rejectedFiles) => {
    if (rejectedFiles && rejectedFiles.length > 0) {
      this.verifyImageFile(rejectedFiles);
    }
    if (files && files.length > 0) {
      if (this.verifyImageFile(files)) {
        const currentFile = files[0];
        const reader = new FileReader();
        reader.addEventListener(
          "load",
          () => {
            this.setState({ image64: reader.result });
          },
          false
        );
        reader.readAsDataURL(currentFile);

        obj.createNotification("success", "File Uploaded");

        this.setState({
          imageFile: currentFile,
          currentFileTypeofData: currentFile.type,
          currentFileNameofData: currentFile.name,
          formData: { ...this.state.formData, upload: currentFile.path },
        });
      }
    }
  };
  verifyImageFile = (files) => {
    if (files && files.length > 0) {
      const currentFile = files[0];
      const currentFileType = currentFile.type;
      const currentFileSize = currentFile.size;
      if (currentFileSize > 2e6) {
        obj.createNotification(
          "error",
          "File should be less than 2mbs, Try Again"
        );
        return false;
      }
      if (!acceptedFileTypesArray.includes(currentFileType)) {
        obj.createNotification(
          "error",
          "This file type is not allowed, Only Word, PDF, and Images are allowed"
        );

        return false;
      }
      return true;
    }
  };
  delete = () => {
    this.setState({ formData: { ...this.state.formData, upload: null } });
  };

  handleSubCategory2 = (selectedOption) => {
    this.setDefaultState();
    this.setState({
      formData: {
        cpd_points_pec: "----",
        sub_category: selectedOption,
        select_category: this.state.selectedCat.value,
      },
    });

    this.state.subcategoriesSet.map((item) => {
      if (item.category === selectedOption) {
        this.setState({
          formID: item.id,
        });
      }
    });

    this.setState(
      {
        selectedSubCat: {
          value: this.state.selectedSubCat.value,
          label: this.state.selectedSubCat.value,
        },
      },
      () => {
        this.props
          .getSubFields(this.state.formID)
          .then((res) => {
            let fildsSet = [];
            for (let i = 0; i < res.length; i++) {
              fildsSet.push({ name: res[i].field_name, id: res[i].field_id });
            }
            let fieldss = [];
            let fieldsSet = {};
            fildsSet.map((item, index) => {
              fieldsSet = { ...fieldsSet, [item.name]: item.name };
              fieldss[index] = {
                [item.name]: res.filter((obj) => {
                  return obj.field_name === item.name;
                }),
              };
            });
            this.setState({ fieldsSet: fieldsSet, fildsSet: fildsSet });
          })
          .catch((err) => {
            console.log(err);
          });
        // points calculation on the basis of subcategory
        this.props
          .getPoints(this.state.selectedSubCat.value)
          .then((res) => {
            res.map((value) => {
              if (value.cpd_remarks == null || value.cpd_remarks == "") {
                this.setState({
                  formData: {
                    ...this.state.formData,
                    cpd_points_pec: value.cpd_points,
                  },
                });
              }
            });
          })
          .catch((err) => {
            console.log(err);
          });
      }
    );
  };
  handleSubCategory = (selectedOption) => {
    this.setDefaultSubCatState();
    this.setState({
      formData: {
        cpd_points_pec: "----",
        sub_category: selectedOption.label,
        select_category: this.state.selectedCat.value,
      },
    });

    this.state.subcategoriesSet.map((item) => {
      if (item.category === selectedOption.value) {
        this.setState({
          formID: item.id,
        });
      }
    });
    this.setState({ selectedSubCat: selectedOption }, () => {
      this.props
        .getSubFields(this.state.formID)
        .then((res) => {
          let fildsSet = [];
          for (let i = 0; i < res.length; i++) {
            fildsSet.push({ name: res[i].field_name, id: res[i].field_id });
          }
          let fieldss = [];
          let fieldsSet = {};
          fildsSet.map((item, index) => {
            fieldsSet = { ...fieldsSet, [item.name]: item.name };
            fieldss[index] = {
              [item.name]: res.filter((obj) => {
                return obj.field_name === item.name;
              }),
            };
          });
          this.setState({ fieldsSet: fieldsSet, fildsSet: fildsSet });
        })
        .catch((err) => {
          console.log(err);
        });
      // points calculation on the basis of subcategory
      this.props
        .getPoints(this.state.selectedSubCat.value)
        .then((res) => {
          res.map((value) => {
            if (value.cpd_remarks == null || value.cpd_remarks == "") {
              this.setState({
                formData: {
                  ...this.state.formData,
                  cpd_points_pec: value.cpd_points,
                },
              });
            }
          });
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  //handle calculation of cpd points
  handleCpdPoints = (e) => {
    let valuee = e.target.value;
    this.setState({
      formData: { ...this.state.formData, [e.target.name]: e.target.value },
    });

    this.props
      .getPoints(this.state.selectedSubCat.value)
      .then((res) => {
        res.map((value) => {
          if (valuee == value.cpd_remarks) {
            this.setState({
              formData: {
                ...this.state.formData,
                cpd_points_pec: value.cpd_points,
              },
            });
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleDropdownChange = (selectedOption) => {
    this.setState({ fieldsSet: "" });
    this.setState(
      {
        selectedCat: selectedOption,
        formData: {
          ...this.state.formData,
          select_category: selectedOption.label,
        },
        selectedSubCat: {
          ...this.state.formData,
          value: "",
          label: "Select a Sub Category",
        },
      },
      () => {
        this.props
          .getSubCategories(this.state.selectedCat.value)
          .then((res) => {
            let subcategoriesSet = [];
            for (let i = 0; i < res.length; i++) {
              subcategoriesSet.push({
                id: res[i].cpd_points_id,
                category: res[i].subcategory,
              });
            }
            let subcategoryy = [];
            let subcat1Set = [];
            subcategoriesSet.map((item, index) => {
              subcat1Set.push({ label: item.category, value: item.category });
              subcategoryy[index] = {
                [item]: res.filter((obj) => {
                  return obj.subcategory === item;
                }),
              };
            });
            this.setState({
              subcat1Set: subcat1Set,
              subcategoriesSet: subcategoriesSet,
            });
          })
          .catch((err) => {
            console.log(err);
          });
      }
    );
  };
  handleModeOfEducation = (selectedOption) => {
    this.setState({
      formData: {
        ...this.state.formData,
        mode_education: selectedOption.value,
      },
      selectMode: selectedOption,
    });
    console.log("SELECTE MODDEEEE: ", this.state.selectMode);
  };

  //handler will take only alphabets
  handleTextChange = (e) => {
    // let val = e.target.value.replace(/[^A-z\s]/g, "");
    let val = e.target.value;
    // console.log('TEXXXXXXXXXXXTTTTTTTTTTTTTTTTTTT: ', val);
    this.setState({
      formData: { ...this.state.formData, [e.target.name]: val },
    });
  };
  //handler for all the fields except dates
  handleChangeText = (e) => {
    this.setState({
      formData: { ...this.state.formData, [e.target.name]: e.target.value },
    });
  };
  handlehourChange = (e) => {
    if (e.target.value < 2) {
      obj.createNotification(
        "error",
        "Minimum hour value to claim cpd points is 2 hours"
      );
    } else {
      this.setState({
        formData: { ...this.state.formData, [e.target.name]: e.target.value },
      });
    }
  };

  handleDaysChange = (e) => {
    if (e.target.value < 1) {
      obj.createNotification(
        "error",
        "Minimum days value to claim cpd points is 1 Day"
      );
    } else {
      this.setState({
        formData: { ...this.state.formData, [e.target.name]: e.target.value },
      });
    }
  };

  // dates handler start
  handleyear_task_completed = (e) => {
    this.setState({
      formData: { ...this.state.formData, year_task_completed: e },
    });
  };
  handleyear_task_assigned = (e) => {
    this.setState({
      formData: { ...this.state.formData, year_task_assigned: e },
    });
  };

  handled_activity = (e) => {
    this.setState({ formData: { ...this.state.formData, d_activity: e } });
  };
  handledateOfEvent = (e) => {
    this.setState({ formData: { ...this.state.formData, dateOfEvent: e } });
  };
  handlepublishingDate = (e) => {
    this.setState({ formData: { ...this.state.formData, publishingDate: e } });
  };
  handlepublish_date = (e) => {
    this.setState({ formData: { ...this.state.formData, publish_date: e } });
  };
  handleyear_reg = (e) => {
    this.setState({ formData: { ...this.state.formData, year_reg: e } });
  };

  handlerenew_upto = (e) => {
    this.setState({ formData: { ...this.state.formData, renew_upto: e } });
  };

  handledateOfAdmission = (e) => {
    this.setState({ formData: { ...this.state.formData, d_admission: e } });
  };

  handledateOfParticipation = (e) => {
    this.setState({
      formData: { ...this.state.formData, participation_date: e },
    });
  };

  handleFromDate = (e) => {
    this.setState(
      { formData: { ...this.state.formData, from_date: e } },
      () => {
        let from = moment(this.state.formData.from_date.getTime());
        if (this.state.formData.to_date) {
          if (this.state.selectedCat.value === "Work Based Activities") {
            // let from = moment(this.state.formData.from_date.getTime());
            let to = moment(this.state.formData.to_date.getTime());
            let toformal = to.add(259200000, "ms");
            var duration = moment.duration(to.diff(from));
            let period = duration.format("MM");
            let md = duration.format("MM-DD");
            let toformaldiff = from.diff(toformal, "days");
            let d;
            let dNew;
            let c;
            if (md.includes("-")) {
              c = md.split("-");
              if (c[0] === "01") {
                d = c[0] + " Month " + c[1] + " Days";
                dNew = c[0] + " Month";
              } else {
                d = c[0] + " Months " + c[1] + " Days";
                dNew = c[0] + " Months";
              }
            } else {
              if (c[0] === "01") {
                d = 0 + " Month " + md + " Days";
                dNew = 0 + " Months";
              } else {
                d = 0 + " Months " + md + " Days";
                dNew = 0 + " Months";
              }
            }
            // let c = md.split("-");
            // let d = c[0] + " Months";
            let daysperiod = to.diff(from, "days");
            this.setState(
              {
                formData: {
                  ...this.state.formData,
                  period: Math.abs(daysperiod),
                  newperiodDays: dNew,
                },
              },
              () => {
                if (
                  this.state.formData.period >= 91 &&
                  this.state.formData.period < 183
                ) {
                  this.setState({
                    formData: {
                      ...this.state.formData,
                      cpd_points_pec: 1,
                    },
                  });
                } else if (this.state.formData.period < 91) {
                  this.setState({
                    formData: {
                      ...this.state.formData,
                      cpd_points_pec: 0,
                    },
                  });
                  obj.createNotification(
                    "error",
                    "Minimum Period required to Claim Points is 3 Months"
                  );
                } else {
                  this.setState({
                    formData: {
                      ...this.state.formData,
                      cpd_points_pec: 2,
                    },
                  });
                }
              }
            );
          } else {
            let to = moment(this.state.formData.to_date.getTime());
            let toformal = to.add(0, "ms");
            var duration = moment.duration(to.diff(from));
            let md = duration.format("MM-DD");
            console.log("mdddd: ", typeof md);
            console.log("mdddd: ", md);
            console.log("mdddd: ", typeof md);
            let d;
            let dNew;
            let c;
            let daysperiod = from.diff(to, "days");
            let toformaldiff = from.diff(toformal, "days");
            if (md.includes("-")) {
              c = md.split("-");
              console.log("ccccc: ", c);
              if (c[0] === "01") {
                d = c[0] + " Month " + c[1] + " Days";
                dNew = c[0] + " Month";
              } else {
                d = c[0] + " Months " + c[1] + " Days";
                dNew = c[0] + " Months";
              }
            } else {
              console.log("inside elseee");
              if (
                this.state.selectedSubCat.label ==
                "Participation in  Training & Development programmes"
              ) {
                console.log("inside else -> if");
                d = 0 + " Months " + md + " Days";
                dNew = 0 + " Months ";
              } else {
                console.log("inside else -> else");
                d = 0 + " Months " + md + " Days";
                dNew = 0 + " Months";
              }
            }
            this.setState(
              {
                formData: {
                  ...this.state.formData,
                  period: Math.abs(daysperiod),
                  toformalperiod: Math.abs(toformaldiff),
                  formaldata: dNew,
                  newperiodDays: d,
                },
              },
              () => {
                this.props
                  .getPoints(this.state.selectedSubCat.value)
                  .then((res) => {
                    if (
                      this.state.selectedSubCat.label ===
                        "Post Graduate Diploma (PGD)" &&
                      this.state.formData.toformalperiod < 183
                    ) {
                      this.setState({
                        formData: {
                          ...this.state.formData,
                          cpd_points_pec: 0,
                        },
                      });
                      document.getElementById("submit-button").disabled = true;
                      obj.createNotification(
                        "error",
                        "Minimum Period required to Claim Points is 6 Months"
                      );
                    } else if (
                      this.state.selectedSubCat.label ===
                        "Post Graduate Diploma (PGD)" &&
                      this.state.formData.toformalperiod >= 183 &&
                      this.state.formData.toformalperiod <= 275
                    ) {
                      document.getElementById("submit-button").disabled = false;
                      this.setState({
                        formData: {
                          ...this.state.formData,
                          cpd_points_pec: res[0].cpd_points,
                        },
                      });
                    } else if (
                      this.state.selectedSubCat.label ===
                        "Post Graduate Diploma (PGD)" &&
                      this.state.formData.toformalperiod > 275
                    ) {
                      document.getElementById("submit-button").disabled = false;
                      this.setState({
                        formData: {
                          ...this.state.formData,
                          cpd_points_pec: res[1].cpd_points,
                        },
                      });
                    } else {
                      // document.getElementById("submit-button").disabled = false;
                    }
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }
            );
          }
        }
      }
    );
  };
  // to date handler with period calcultor and points calculation
  handleToDate = (e) => {
    this.setState({ formData: { ...this.state.formData, to_date: e } }, () => {
      if (this.state.formData.from_date) {
        if (this.state.selectedCat.value === "Work Based Activities") {
          let from = moment(this.state.formData.from_date.getTime());
          let to = moment(this.state.formData.to_date.getTime());
          let toformal = to.add(259200000, "ms");
          var duration = moment.duration(to.diff(from));
          // let period = duration.format("MM");
          let md = duration.format("MM-DD");
          let d;
          let c;
          let dNew;
          let daysperiod = to.diff(from, "days");
          let toformaldiff = from.diff(toformal, "days");
          if (md.includes("-")) {
            c = md.split("-");
            if (c[0] === "01") {
              d = c[0] + " Month " + c[1] + " Days";
              dNew = c[0] + " Month ";
            } else {
              d = c[0] + " Months " + c[1] + " Days";
              dNew = c[0] + " Months ";
            }
          } else {
            d = 0 + " Months " + md + " Days";
            dNew = 0 + " Months";
          }
          this.setState(
            {
              formData: {
                ...this.state.formData,
                period: Math.abs(daysperiod),
                newperiodDays: dNew,
              },
            },
            () => {
              if (
                this.state.formData.period >= 91 &&
                this.state.formData.period < 183
              ) {
                this.setState({
                  formData: {
                    ...this.state.formData,
                    cpd_points_pec: 1,
                  },
                });
              } else if (this.state.formData.period < 91) {
                this.setState({
                  formData: {
                    ...this.state.formData,
                    cpd_points_pec: 0,
                  },
                });
                obj.createNotification(
                  "error",
                  "Minimum Period required to Claim Points is 3 Months"
                );
              } else {
                this.setState({
                  formData: {
                    ...this.state.formData,
                    cpd_points_pec: 2,
                  },
                });
              }
            }
          );
        } else {
          let from = moment(this.state.formData.from_date.getTime());
          let to = moment(this.state.formData.to_date.getTime());
          let toformal = to.add(0, "ms");
          var duration = moment.duration(to.diff(from));
          let md = duration.format("MM-DD");
          console.log("mdddd: ", md);
          let d;
          let c;
          let dNew;
          let daysperiod = to.diff(from, "days");
          let toformaldiff = from.diff(toformal, "days");
          if (md.includes("-")) {
            c = md.split("-");
            console.log("ccccc: ", c);
            if (c[0] === "01") {
              d = c[0] + " Month " + c[1] + " Days";
              dNew = c[0] + " Month ";
            } else {
              d = c[0] + " Months " + c[1] + " Days";
              dNew = c[0] + " Months ";
            }
          } else {
            console.log("inside elseee");
            if (
              this.state.selectedSubCat.label ==
              "Participation in  Training & Development programmes"
            ) {
              console.log("inside else -> if");
              d = 0 + " Months " + md + " Days";
              dNew = 0 + " Months ";
            } else {
              console.log("inside else -> else");
              d = 0 + " Months " + md + " Days";
              dNew = 0 + " Months";
            }
          }
          this.setState(
            {
              formData: {
                ...this.state.formData,
                period: Math.abs(daysperiod),
                toformalperiod: Math.abs(toformaldiff),
                formaldata: dNew,
                newperiodDays: d,
              },
            },
            () => {
              this.props
                .getPoints(this.state.selectedSubCat.value)
                .then((res) => {
                  if (
                    this.state.selectedSubCat.label ===
                      "Post Graduate Diploma (PGD)" &&
                    this.state.formData.toformalperiod >= 183 &&
                    this.state.formData.toformalperiod <= 275
                  ) {
                    document.getElementById("submit-button").disabled = false;
                    this.setState({
                      formData: {
                        ...this.state.formData,
                        cpd_points_pec: res[0].cpd_points,
                      },
                    });
                  } else if (
                    this.state.selectedSubCat.label ===
                      "Post Graduate Diploma (PGD)" &&
                    this.state.formData.toformalperiod < 183
                  ) {
                    this.setState({
                      formData: {
                        ...this.state.formData,
                        cpd_points_pec: 0,
                      },
                    });
                    document.getElementById("submit-button").disabled = true;
                    obj.createNotification(
                      "error",
                      "Minimum Period required to Claim Points is 6 Months"
                    );
                  } else if (
                    this.state.selectedSubCat.label ===
                      "Post Graduate Diploma (PGD)" &&
                    this.state.formData.toformalperiod > 275
                  ) {
                    document.getElementById("submit-button").disabled = false;
                    this.setState({
                      formData: {
                        ...this.state.formData,
                        cpd_points_pec: res[1].cpd_points,
                      },
                    });
                  } else {
                    // document.getElementById("submit-button").disabled = false;
                  }
                })
                .catch((err) => {
                  console.log(err);
                });
            }
          );
        }
      }
    });
  };

  handleFileUploadChange(event) {
    this.setState({
      fileUploadUrl: URL.createObjectURL(event.target.upload[0]),
    });
  }

  render() {
    const options = {
      filter: false,
      search: false,
      print: false,
      selectableRows: false,
      download: false,
      viewColumns: false,
      // selectableRows: "none",
      customToolbar: null,
      responsive: "vertical",
      onRowsDelete: (oldData) => {
        let deletedIndex = oldData.data[0].dataIndex;
        this.setState(
          () => {
            let b = [];
            let c = [];
            let d = [];
            this.state.globalForms.map((item, index) => {
              if (index === deletedIndex) {
              } else {
                d.push(item);
              }
            });
            this.state.formMui.map((item, index) => {
              if (index === deletedIndex) {
              } else {
                c.push(item);
              }
            });
            this.state.formList.filter((item, index) => {
              if (index === deletedIndex) {
                console.log("not pushed");
              } else {
                b.push(item);
              }
            });

            return {
              ...this.state,
              formList: b,
              formMui: c,
              globalForms: d,
            };
          },
          () => {
            localStorage.setItem("formMui", JSON.stringify(this.state.formMui));
            localStorage.setItem(
              "formList",
              JSON.stringify(this.state.formList)
            );
            localStorage.setItem(
              "globalForms",
              JSON.stringify(this.state.globalForms)
            );
          }
        );
      },
    };
    return (
      <React.Fragment>
        <Prompt
          when={this.state.shouldBlockNavigation}
          message="You have unsaved changes, are you sure you want to leave?"
        />
        <Header />
        <div className="container mtb-50 plr-70">
          <h3>Claim CPD Points</h3>
          <Link
            to={"/CPDBriefs"}
            style={{
              textAlign: "left",
              marginLeft: "20px",
              marginTop: "10px",
            }}
            className="bold font_sans fz_13"
            target="_blank"
            // download
          >
            CPD Calculator
          </Link>
          <hr />
          <form>
            <LoadingOverlay active={this.state.loader ? true : false} spinner>
              <label htmlFor="FormControlSelect1" className="fw-600-fs-13">
                Select Category <span className="steric">*</span>
              </label>
              <div className="row">
                <div className="col-4">
                  <Select
                    className="mtb-10  cat-select"
                    style={{ width: "175px" }}
                    name="cat1Set"
                    options={this.state.cat1Set}
                    value={this.state.selectedCat}
                    onChange={this.handleDropdownChange}
                  />
                </div>

                <div className="col-4">
                  <Select
                    className="mtb-10 cat-select"
                    style={{ width: "175px" }}
                    name="subcat1Set"
                    options={this.state.subcat1Set}
                    value={this.state.selectedSubCat}
                    onChange={this.handleSubCategory}
                  />
                </div>
              </div>
              <div className="form-group mb-20">
                {this.state.fieldsSet && this.state.fieldsSet.from_date && (
                  <div className="row mt-20">
                    <div className="col">
                      <div>
                        <label
                          htmlFor="from_dateInput"
                          className="fw-600-fs-13"
                        >
                          From <span className="steric">*</span>
                        </label>
                        <div>
                          <DatePicker
                            className="form-control cat-select"
                            name="from_date"
                            autoComplete="off"
                            selected={this.state.formData.from_date}
                            onChange={this.handleFromDate}
                            minDate={new Date("2010/07/01")}
                            maxDate={new Date()}
                            onKeyDown={(e) => {
                              e.preventDefault();
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col">
                      {this.state.fieldsSet &&
                        this.state.fieldsSet.from_date && (
                          <div>
                            <label
                              htmlFor="to_dateInput"
                              className="fw-600-fs-13"
                            >
                              To <span className="steric">*</span>
                            </label>
                            <div>
                              <DatePicker
                                className="form-control cat-select"
                                name="to_date"
                                autoComplete="off"
                                selected={this.state.formData.to_date}
                                maxDate={new Date()}
                                minDate={new Date("2010/07/01")}
                                onChange={this.handleToDate}
                                onKeyDown={(e) => {
                                  e.preventDefault();
                                }}
                              />
                            </div>
                          </div>
                        )}
                    </div>

                    <div className="col">
                      {this.state.fieldsSet &&
                        this.state.fieldsSet.from_date && (
                          <div>
                            <label htmlFor="period" className="fw-600-fs-13">
                              Period (Months)
                            </label>

                            <div>
                              <input
                                style={{ backgroundColor: "white" }}
                                type="text"
                                value={
                                  this.state.selectedCat.value ===
                                  "Formal Education"
                                    ? this.state.formData.formaldata
                                    : this.state.formData.newperiodDays
                                }
                                className="form-control fields-input-cpd"
                                placeholder="0 Months"
                                readOnly
                              />
                            </div>
                          </div>
                        )}
                    </div>
                  </div>
                )}

                {this.state.fieldsSet && this.state.fieldsSet.hoursDuration && (
                  <div className="mtb-20 ">
                    <label htmlFor="toLInput" className="fw-600-fs-13">
                      Duration In Hours <span className="steric">*</span>
                    </label>
                    <input
                      type="number"
                      name="hoursDuration"
                      min={2}
                      value={this.state.formData.hoursDuration}
                      onChange={this.handlehourChange}
                      className="form-control fields-input-cpd"
                      id="toLInput"
                      style={{ fontSize: "13px" }}
                      aria-describedby="emailHelp"
                      placeholder="Enter the duration in hours"
                    />
                  </div>
                )}
                {this.state.fieldsSet && this.state.fieldsSet.daysDuration && (
                  <div className="mtb-20 ">
                    <label htmlFor="toLInput" className="fw-600-fs-13">
                      Duration In Days <span className="steric">*</span>
                    </label>
                    <input
                      type="number"
                      name="daysDuration"
                      min={1}
                      value={this.state.formData.daysDuration}
                      onChange={this.handleDaysChange}
                      className="form-control fields-input-cpd"
                      id="toLInput"
                      style={{ fontSize: "13px" }}
                      aria-describedby="emailHelp"
                      placeholder="Enter the duration in Days"
                    />
                  </div>
                )}
                {this.state.fieldsSet && this.state.fieldsSet.title_lecture && (
                  <div className="mtb-20 ">
                    <label htmlFor="toLInput" className="fw-600-fs-13">
                      Title Of Lecture <span className="steric">*</span>
                    </label>
                    <input
                      type="text"
                      name="title_lecture"
                      value={this.state.formData.title_lecture}
                      onChange={this.handleTextChange}
                      className="form-control fields-input-cpd"
                      id="toLInput"
                      style={{ fontSize: "13px" }}
                      aria-describedby="emailHelp"
                      placeholder="Enter the title of Lectures"
                    />
                  </div>
                )}
                {this.state.fieldsSet &&
                  this.state.fieldsSet.title_symposium && (
                    <div className="mtb-20 ">
                      <label htmlFor="toSInput" className="fw-600-fs-13">
                        Title Of Symposium <span className="steric">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control fields-input-cpd"
                        id="toSInput"
                        name="title_symposium"
                        style={{ fontSize: "13px" }}
                        value={this.state.formData.title_symposium}
                        onChange={this.handleTextChange}
                        aria-describedby="emailHelp"
                        placeholder="Enter the title of symposium"
                      />
                    </div>
                  )}

                {this.state.fieldsSet &&
                  this.state.fieldsSet.title_research_article && (
                    <div className="mtb-20 ">
                      <label htmlFor="toRInput" className="fw-600-fs-13">
                        Title of Research Article{" "}
                        <span className="steric">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control fields-input-cpd"
                        id="toRInput"
                        name="title_research_article"
                        style={{ fontSize: "13px" }}
                        value={this.state.formData.title_research_article}
                        onChange={this.handleTextChange}
                        aria-describedby="emailHelp"
                        placeholder="Enter the title of research article"
                      />
                    </div>
                  )}
                {this.state.fieldsSet && this.state.fieldsSet.n_journal && (
                  <div className="mtb-20 ">
                    <label htmlFor="nofJInput" className="fw-600-fs-13">
                      Name Of Journal / Technical Report{" "}
                      <span className="steric">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control fields-input-cpd"
                      id="nofJInput"
                      name="n_journal"
                      style={{ fontSize: "13px" }}
                      value={this.state.formData.n_journal}
                      onChange={this.handleTextChange}
                      aria-describedby="emailHelp"
                    />
                  </div>
                )}

                {participation_array.includes(
                  this.state.selectedSubCat.label
                ) && (
                  <div className="mtb-20">
                    <label htmlFor="d_admissionInput" className="fw-600-fs-13">
                      Participation Date <span className="steric">*</span>
                    </label>
                    <div>
                      <DatePicker
                        className="form-control cat-select"
                        name="participation_date"
                        autoComplete="off"
                        selected={this.state.formData.participation_date}
                        onChange={this.handledateOfParticipation}
                        minDate={new Date("1970/01/01")}
                        maxDate={new Date()}
                      />
                    </div>
                  </div>
                )}

                {activity_array.includes(this.state.selectedSubCat.label) && (
                  <div className="mtb-20">
                    <label htmlFor="d_admissionInput" className="fw-600-fs-13">
                      Activity Date <span className="steric">*</span>
                    </label>
                    <div>
                      <DatePicker
                        className="form-control cat-select"
                        name="participation_date"
                        autoComplete="off"
                        selected={this.state.formData.participation_date}
                        onChange={this.handledateOfParticipation}
                        minDate={new Date("1970/01/01")}
                        maxDate={new Date()}
                      />
                    </div>
                  </div>
                )}

                {this.state.fieldsSet && this.state.fieldsSet.t_conference && (
                  <div className="mtb-20 ">
                    <label
                      htmlFor="tOFConferenceInput"
                      className="fw-600-fs-13"
                    >
                      Title Of Conference <span className="steric">*</span>
                    </label>
                    <input
                      type="text"
                      name="t_conference"
                      value={this.state.formData.t_conference}
                      onChange={this.handleTextChange}
                      className="form-control fields-input-cpd"
                      id="tOFConferenceInput"
                      style={{ fontSize: "13px" }}
                      aria-describedby="emailHelp"
                      placeholder="Enter the title of conference"
                    />
                  </div>
                )}
                {this.state.fieldsSet && this.state.fieldsSet.title_seminar && (
                  <div className="mtb-20">
                    <label htmlFor="pBodyInput" className="fw-600-fs-13">
                      Title Of Seminar <span className="steric">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control fields-input-cpd"
                      name="title_seminar"
                      value={this.state.formData.title_seminar}
                      onChange={this.handleTextChange}
                      id="pBodyInput"
                      style={{ fontSize: "13px" }}
                      aria-describedby="emailHelp"
                    />
                  </div>
                )}
                {this.state.fieldsSet &&
                  this.state.fieldsSet.title_Workshop && (
                    <div className="mtb-20">
                      <label htmlFor="pBodyInput" className="fw-600-fs-13">
                        Title Of Workshop <span className="steric">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control fields-input-cpd"
                        name="title_Workshop"
                        style={{ fontSize: "13px" }}
                        value={this.state.formData.title_Workshop}
                        onChange={this.handleTextChange}
                        id="pBodyInput"
                        aria-describedby="emailHelp"
                      />
                    </div>
                  )}
                {this.state.fieldsSet &&
                  this.state.fieldsSet.title_course_short && (
                    <div className="mtb-20">
                      <label htmlFor="pBodyInput" className="fw-600-fs-13">
                        Title Of Course / Short Course{" "}
                        <span className="steric">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control fields-input-cpd"
                        name="title_course_short"
                        style={{ fontSize: "13px" }}
                        value={this.state.formData.title_course_short}
                        onChange={this.handleTextChange}
                        id="pBodyInput"
                        aria-describedby="emailHelp"
                      />
                    </div>
                  )}

                {this.state.fieldsSet &&
                  this.state.fieldsSet.title_training && (
                    <div className="mtb-20 ">
                      <label htmlFor="titleTInput" className="fw-600-fs-13">
                        Title Of Training <span className="steric">*</span>
                      </label>
                      <input
                        type="text"
                        name="title_training"
                        style={{ fontSize: "13px" }}
                        value={this.state.formData.title_training}
                        onChange={this.handleTextChange}
                        className="form-control fields-input-cpd"
                        id="titleTInput"
                        aria-describedby="emailHelp"
                        placeholder="Enter the title of training"
                      />
                    </div>
                  )}
                {this.state.fieldsSet &&
                  this.state.fieldsSet.name_inst_pbody && (
                    <div className="mt-20">
                      <label htmlFor="pBodyInput" className="fw-600-fs-13">
                        Name Of Institution / Professional Body{" "}
                        <span className="steric">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control fields-input-cpd"
                        name="name_inst_pbody"
                        style={{ fontSize: "13px" }}
                        value={this.state.formData.name_inst_pbody}
                        onChange={this.handleTextChange}
                        id="pBodyInput"
                        aria-describedby="emailHelp"
                      />
                    </div>
                  )}

                {this.state.fieldsSet &&
                  this.state.fieldsSet.title_conference_paper && (
                    <div className="mtb-20 ">
                      <label htmlFor="tOCPInput" className="fw-600-fs-13">
                        Title Of Conference Paper{" "}
                        <span className="steric">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control fields-input-cpd"
                        id="tOCPInput"
                        name="title_conference_paper"
                        style={{ fontSize: "13px" }}
                        value={this.state.formData.title_conference_paper}
                        onChange={this.handleTextChange}
                        aria-describedby="emailHelp"
                        placeholder="Enter the title of research article"
                      />
                    </div>
                  )}
                {this.state.fieldsSet && this.state.fieldsSet.name_host_org && (
                  <div className="mtb-20 ">
                    <label htmlFor="mOFHOInput" className="fw-600-fs-13">
                      Name Of Host Organization{" "}
                      <span className="steric">*</span>
                    </label>
                    <input
                      type="text"
                      name="name_host_org"
                      value={this.state.formData.name_host_org}
                      onChange={this.handleTextChange}
                      className="form-control fields-input-cpd"
                      style={{ fontSize: "13px" }}
                      id="mOFHOInput"
                      aria-describedby="emailHelp"
                    />
                  </div>
                )}
                {this.state.fieldsSet && this.state.fieldsSet.loco && (
                  <div className="mtb-20 ">
                    <label htmlFor="locationInput" className="fw-600-fs-13">
                      Location <span className="steric">*</span>
                    </label>
                    <input
                      type="text"
                      name="locationInput"
                      value={this.state.formData.locationInput}
                      onChange={this.handleTextChange}
                      className="form-control fields-input-cpd"
                      style={{ fontSize: "13px" }}
                      id="locationInput"
                      aria-describedby="emailHelp"
                    />
                  </div>
                )}

                {this.state.fieldsSet &&
                  this.state.fieldsSet.mode_education && (
                    <div className="mtb-20 ">
                      <label htmlFor="MOFBInput" className="fw-600-fs-13">
                        Mode Of Education <span className="steric">*</span>
                      </label>

                      <Select
                        className="mtb-10 newselect"
                        // style={{ width: "175px" }}
                        name="modeofeducation"
                        options={modeofeducation}
                        value={this.state.selectMode}
                        onChange={this.handleModeOfEducation}
                      />

                      {/* <input
                    type="text"
                    className="form-control fields-input-cpd"
                    id="MOFBInput"
                    name="mode_education"
                    value={this.state.mode_education}
                    onChange={this.handleChangeText}
                    aria-describedby="emailHelp"
                    placeholder="Enter the Mode Education"
                  /> */}
                    </div>
                  )}
                {this.state.fieldsSet && this.state.fieldsSet.t_book && (
                  <div className="mtb-20 ">
                    <label htmlFor="tOFBInput" className="fw-600-fs-13">
                      Title Of Book <span className="steric">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control fields-input-cpd"
                      id="tOFBInput"
                      name="t_book"
                      value={this.state.formData.t_book}
                      onChange={this.handleTextChange}
                      style={{ fontSize: "13px" }}
                      aria-describedby="emailHelp"
                      placeholder="Enter the title of Book"
                    />
                  </div>
                )}
                {this.state.fieldsSet &&
                  this.state.fieldsSet.book_publisher && (
                    <div className="mtb-20 ">
                      <label htmlFor="nOFTBInput" className="fw-600-fs-13">
                        Name Of The Book Publisher{" "}
                        <span className="steric">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control fields-input-cpd"
                        name="book_publisher"
                        value={this.state.formData.book_publisher}
                        onChange={this.handleTextChange}
                        style={{ fontSize: "13px" }}
                        id="nOFTBInput"
                        aria-describedby="emailHelp"
                      />
                    </div>
                  )}

                {this.state.fieldsSet &&
                  this.state.fieldsSet.approving_authority && (
                    <div className="mtb-20 ">
                      <label htmlFor="aAuthortiyInput" className="fw-600-fs-13">
                        Approving Authority <span className="steric">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control fields-input-cpd"
                        name="approving_authority"
                        value={this.state.formData.approving_authority}
                        onChange={this.handleTextChange}
                        style={{ fontSize: "13px" }}
                        id="aAuthortiyInput"
                        aria-describedby="emailHelp"
                      />
                    </div>
                  )}
                {this.state.fieldsSet &&
                  this.state.fieldsSet.publishing_date && (
                    <div className="mtb-20 ">
                      <label htmlFor="p-DateInput" className="fw-600-fs-13">
                        Publishing Date <span className="steric">*</span>
                      </label>
                      <div>
                        <DatePicker
                          className="form-control cat-select"
                          name="publishingDate"
                          autoComplete="off"
                          selected={this.state.formData.publishingDate}
                          onChange={this.handlepublishingDate}
                          minDate={new Date("1970/01/01")}
                          maxDate={new Date()}
                        />
                      </div>
                    </div>
                  )}
                {this.state.fieldsSet && this.state.fieldsSet.ref_link && (
                  <div className="mt-20">
                    <label htmlFor="pBodyInput" className="fw-600-fs-13">
                      Reference Link
                    </label>
                    <input
                      type="text"
                      className="form-control fields-input-cpd"
                      name="ref_link"
                      value={this.state.formData.ref_link}
                      onChange={this.handleTextChange}
                      style={{ fontSize: "13px" }}
                      id="pBodyInput"
                      aria-describedby="emailHelp"
                    />
                  </div>
                )}
                {this.state.fieldsSet &&
                  this.state.fieldsSet.nature_social_work && (
                    <div className="mtb-20 ">
                      <label htmlFor="nOFSWInput" className="fw-600-fs-13">
                        Nature Of Social Work <span className="steric">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control fields-input-cpd"
                        name="nature_social_work"
                        value={this.state.formData.nature_social_work}
                        onChange={this.handleTextChange}
                        style={{ fontSize: "13px" }}
                        id="nOFSWInput"
                        aria-describedby="emailHelp"
                      />
                    </div>
                  )}
                {this.state.fieldsSet && this.state.fieldsSet.r_social_work && (
                  <div className="mtb-20 ">
                    <label htmlFor="roleSWInput" className="fw-600-fs-13">
                      Role In Social Work <span className="steric">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control fields-input-cpd"
                      name="r_social_work"
                      value={this.state.formData.r_social_work}
                      onChange={this.handleTextChange}
                      style={{ fontSize: "13px" }}
                      id="roleSWInput"
                      aria-describedby="emailHelp"
                    />
                  </div>
                )}

                {this.state.fieldsSet &&
                  this.state.fieldsSet.nature_committee && (
                    <div className="mtb-20 ">
                      <label
                        htmlFor="nOFCommitteeInput"
                        className="fw-600-fs-13"
                      >
                        Nature Of Committee <span className="steric">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control fields-input-cpd"
                        name="nature_committee"
                        value={this.state.formData.nature_committee}
                        onChange={this.handleTextChange}
                        style={{ fontSize: "13px" }}
                        id="nOFCommitteeInput"
                        aria-describedby="emailHelp"
                      />
                    </div>
                  )}

                {this.state.fieldsSet &&
                  this.state.fieldsSet.role_committee && (
                    <div className="mtb-20 ">
                      <label
                        htmlFor="rINCommitteeInput"
                        className="fw-600-fs-13"
                      >
                        Role In Committee <span className="steric">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control fields-input-cpd"
                        name="role_committee"
                        value={this.state.formData.role_committee}
                        onChange={this.handleTextChange}
                        style={{ fontSize: "13px" }}
                        id="rINCommitteeInput"
                        aria-describedby="emailHelp"
                      />
                    </div>
                  )}
                {this.state.fieldsSet && this.state.fieldsSet.nature_event && (
                  <div className="mtb-20 ">
                    <label htmlFor="natureOFEInput" className="fw-600-fs-13">
                      Nature Of Event <span className="steric">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control fields-input-cpd"
                      name="nature_event"
                      value={this.state.formData.nature_event}
                      onChange={this.handleTextChange}
                      style={{ fontSize: "13px" }}
                      id="natureOFEInput"
                      aria-describedby="emailHelp"
                    />
                  </div>
                )}

                {this.state.fieldsSet && this.state.fieldsSet.role_event && (
                  <div className="mtb-20 ">
                    <label htmlFor="roleInEInput" className="fw-600-fs-13">
                      Role In Event <span className="steric">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control fields-input-cpd"
                      name="role_event"
                      value={this.state.formData.role_event}
                      onChange={this.handleTextChange}
                      style={{ fontSize: "13px" }}
                      id="roleInEInput"
                      aria-describedby="emailHelp"
                    />
                  </div>
                )}
                {this.state.fieldsSet &&
                  this.state.fieldsSet.location_event && (
                    <div className="mtb-20">
                      <label
                        htmlFor="location_eventInput"
                        className="fw-600-fs-13"
                      >
                        Location Of Event <span className="steric">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control fields-input-cpd"
                        name="location_event"
                        value={this.state.formData.location_event}
                        onChange={this.handleTextChange}
                        style={{ fontSize: "13px" }}
                        id="location_eventInput"
                        aria-describedby="emailHelp"
                      />
                    </div>
                  )}
                {this.state.fieldsSet &&
                  this.state.fieldsSet.notifying_authority && (
                    <div className="mtb-20 ">
                      <label
                        htmlFor="notifying_authorityInput"
                        className="fw-600-fs-13"
                      >
                        Notifying Authority <span className="steric">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control fields-input-cpd"
                        name="notifying_authority"
                        value={this.state.formData.notifying_authority}
                        onChange={this.handleTextChange}
                        style={{ fontSize: "13px" }}
                        id="notifying_authorityInput"
                        aria-describedby="emailHelp"
                      />
                    </div>
                  )}
                {this.state.fieldsSet &&
                  this.state.fieldsSet.tors_committee && (
                    <div className="mtb-20 ">
                      <label
                        htmlFor="rOFCommitteeInput"
                        className="fw-600-fs-13"
                      >
                        TORs Of Committee <span className="steric">*</span>
                      </label>
                      <textarea
                        className="form-control fields-input-cpd"
                        name="tors_committee"
                        id="rOFCommitteeInput"
                        value={this.state.formData.tors_committee}
                        onChange={this.handleTextChange}
                        style={{ fontSize: "13px" }}
                        rows="5"
                        cols="33"
                      ></textarea>
                      {/* 
                  <input
                    type="textarea"
                    
                    name="tors_committee"
                    value={this.state.tors_committee}
                    onChange={this.handleChangeText}
                    id="rOFCommitteeInput"
                    aria-describedby="emailHelp"
                  /> */}
                    </div>
                  )}

                <div class="row mtb-20">
                  {this.state.fieldsSet &&
                    this.state.fieldsSet.year_task_assigned && (
                      <div className="col">
                        <label htmlFor="yearOTAInput" className="fw-600-fs-13">
                          Year Of Task Assigned{" "}
                          <span className="steric">*</span>
                        </label>
                        <div>
                          <DatePicker
                            className="form-control cat-select"
                            name="year_task_assigned"
                            autoComplete="off"
                            selected={this.state.formData.year_task_assigned}
                            onChange={this.handleyear_task_assigned}
                            minDate={new Date("1970")}
                            maxDate={new Date()}
                          />
                        </div>
                      </div>
                    )}
                  {this.state.fieldsSet &&
                    this.state.fieldsSet.year_task_completed && (
                      <div className="col">
                        <label htmlFor="yearOTCInput" className="fw-600-fs-13">
                          Year Of Task Completed{" "}
                          <span className="steric">*</span>
                        </label>
                        <div>
                          <DatePicker
                            className="form-control cat-select"
                            name="year_task_completed"
                            autoComplete="off"
                            selected={this.state.formData.year_task_completed}
                            onChange={this.handleyear_task_completed}
                            minDate={new Date("1970")}
                            maxDate={new Date()}
                          />
                        </div>
                      </div>
                    )}
                  {this.state.fieldsSet &&
                    this.state.fieldsSet.publish_date && (
                      <div className="col ">
                        <label htmlFor="pDateInput" className="fw-600-fs-13">
                          Publish Date <span className="steric">*</span>
                        </label>
                        <div>
                          <DatePicker
                            className="form-control cat-select"
                            name="publish_date"
                            autoComplete="off"
                            selected={this.state.formData.publish_date}
                            onChange={this.handlepublish_date}
                            minDate={new Date("1970/01/01")}
                            maxDate={new Date()}
                          />
                        </div>
                      </div>
                    )}
                </div>

                {this.state.fieldsSet && this.state.fieldsSet.d_activity && (
                  <div className="mtb-20 ">
                    <label htmlFor="dOFAInput" className="fw-600-fs-13">
                      Date Of Activity <span className="steric">*</span>
                    </label>
                    <div>
                      <DatePicker
                        className="form-control cat-select"
                        name="d_activity"
                        autoComplete="off"
                        selected={this.state.formData.d_activity}
                        onChange={this.handled_activity}
                        minDate={new Date("1970/01/01")}
                        maxDate={new Date()}
                      />
                    </div>
                  </div>
                )}
                {this.state.fieldsSet && this.state.fieldsSet.n_p_body && (
                  <div>
                    <label
                      htmlFor="ProfessionalBodyInput"
                      className="fw-600-fs-13"
                    >
                      Name Of Professional Body{" "}
                      <span className="steric">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control fields-input-cpd"
                      id="ProfessionalBodyInput"
                      name="n_p_body"
                      style={{ fontSize: "13px" }}
                      value={this.state.formData.n_p_body}
                      onChange={this.handleTextChange}
                      aria-describedby="emailHelp"
                      placeholder="Enter the name of Professional Body"
                    />
                  </div>
                )}

                {this.state.fieldsSet && this.state.fieldsSet.uni_inst_name && (
                  <div className="mtb-20">
                    <label htmlFor="uni_inst_name" className="fw-600-fs-13">
                      University / Institution Name{" "}
                      <span className="steric">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control fields-input-cpd"
                      name="uni_inst_name"
                      value={this.state.formData.uni_inst_name}
                      onChange={this.handleTextChange}
                      style={{ fontSize: "13px" }}
                      id="uni_inst_name"
                      aria-describedby="emailHelp"
                      placeholder="Enter the name of your university / institution"
                    />
                  </div>
                )}
                {this.state.fieldsSet &&
                  this.state.fieldsSet.name_organization && (
                    <div className="mb-20">
                      <label
                        htmlFor="OrganizationInput"
                        className="fw-600-fs-13"
                      >
                        Name Of Organization <span className="steric">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control fields-input-cpd"
                        id="OrganizationInput"
                        style={{ fontSize: "13px" }}
                        name="name_organization"
                        value={this.state.formData.name_organization}
                        onChange={this.handleTextChange}
                        aria-describedby="emailHelp"
                        placeholder="Enter the name of your Organization"
                      />
                    </div>
                  )}
                {this.state.fieldsSet && this.state.fieldsSet.designation && (
                  <div className="mtb-20">
                    <label htmlFor="designation" className="fw-600-fs-13">
                      Designation <span className="steric">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control fields-input-cpd"
                      id="designation"
                      style={{ fontSize: "13px" }}
                      name="designation"
                      value={this.state.formData.designation}
                      onChange={this.handleTextChange}
                      aria-describedby="emailHelp"
                    />
                  </div>
                )}
                {this.state.fieldsSet && this.state.fieldsSet.org_type && (
                  <div className="mt-20">
                    <label
                      htmlFor="organizationTypeInput"
                      className="fw-600-fs-13"
                    >
                      Organization Type <span className="steric">*</span>
                    </label>
                    <div id="organizationTypeGroup">
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="org_type"
                          style={{ fontSize: "13px" }}
                          value="Public"
                          onChange={this.handleChangeText}
                          id="organizationTypeRadio1"
                        />
                        <label
                          className="form-check-label fs-12"
                          htmlFor="organizationTypeRadio1"
                        >
                          Public
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          style={{ fontSize: "13px" }}
                          name="org_type"
                          value="Private"
                          onChange={this.handleChangeText}
                          id="organizationTypeRadio2"
                        />
                        <label
                          className="form-check-label fs-12"
                          htmlFor="organizationTypeRadio2"
                        >
                          Private
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          style={{ fontSize: "13px" }}
                          name="org_type"
                          value="Self Employed"
                          onChange={this.handleChangeText}
                          id="organizationTypeRadio2"
                        />
                        <label
                          className="form-check-label fs-12"
                          htmlFor="organizationTypeRadio2"
                        >
                          Self Employed
                        </label>
                      </div>
                    </div>
                  </div>
                )}

                {this.state.fieldsSet &&
                  this.state.fieldsSet.nature_activitiesh && (
                    <div className="mtb-20">
                      <label htmlFor="NatureInput" className="fw-600-fs-13">
                        Nature Of Activities <span className="steric">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control fields-input-cpd"
                        name="nature_activities"
                        style={{ fontSize: "13px" }}
                        value={this.state.formData.nature_activities}
                        onChange={this.handleTextChange}
                        id="NatureInput"
                        aria-describedby="emailHelp"
                      />
                    </div>
                  )}
                {this.state.fieldsSet && this.state.fieldsSet.title_degree && (
                  <div className="mtb-20">
                    <label htmlFor="title_degree" className="fw-600-fs-13">
                      Title Of Degree <span className="steric">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control fields-input-cpd"
                      name="title_degree"
                      style={{ fontSize: "13px" }}
                      value={this.state.formData.title_degree}
                      onChange={this.handleTextChange}
                      id="title_degree"
                      aria-describedby="emailHelp"
                    />
                  </div>
                )}
                {this.state.fieldsSet && this.state.fieldsSet.title_diploma && (
                  <div className="mtb-20">
                    <label htmlFor="title_diploma" className="fw-600-fs-13">
                      Title Of Diploma <span className="steric">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control fields-input-cpd"
                      id="title_diploma"
                      name="title_diploma"
                      style={{ fontSize: "13px" }}
                      value={this.state.formData.title_diploma}
                      onChange={this.handleTextChange}
                      placeholder="Enter title of diploma/degree"
                      aria-describedby="emailHelp"
                    />
                  </div>
                )}
                {this.state.fieldsSet && this.state.fieldsSet.d_admission && (
                  <div className="mtb-20">
                    <label htmlFor="d_admissionInput" className="fw-600-fs-13">
                      Date Of Admission <span className="steric">*</span>
                    </label>
                    <div>
                      <DatePicker
                        className="form-control cat-select"
                        name="d_admission"
                        autoComplete="off"
                        selected={this.state.formData.d_admission}
                        onChange={this.handledateOfAdmission}
                        minDate={new Date("1970/01/01")}
                        maxDate={new Date()}
                      />
                    </div>
                  </div>
                )}
                {this.state.fieldsSet && this.state.fieldsSet.date_event && (
                  <div className="mtb-20">
                    <label htmlFor="DateOfEventInput" className="fw-600-fs-13">
                      Date Of Event <span className="steric">*</span>
                    </label>
                    <div>
                      <DatePicker
                        className="form-control cat-select"
                        name="dateOfEvent"
                        autoComplete="off"
                        selected={this.state.formData.dateOfEvent}
                        onChange={this.handledateOfEvent}
                        minDate={new Date("1970/01/01")}
                        maxDate={new Date()}
                      />
                    </div>
                  </div>
                )}
                <div className="row ">
                  <div className="col">
                    {this.state.fieldsSet && this.state.fieldsSet.year_reg && (
                      <div className="mtb-20">
                        <label
                          htmlFor="YearofRegInput"
                          className="fw-600-fs-13"
                        >
                          Year Of Registration <span className="steric">*</span>
                        </label>
                        <div>
                          <DatePicker
                            className="form-control cat-select"
                            name="year_reg"
                            autoComplete="off"
                            selected={this.state.formData.year_reg}
                            onChange={this.handleyear_reg}
                            minDate={new Date("1970/01/01")}
                            maxDate={new Date()}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="col">
                    {this.state.fieldsSet &&
                      this.state.fieldsSet.renew_upto && (
                        <div className="mtb-20">
                          <label
                            htmlFor="renewUptoInput"
                            className="fw-600-fs-13"
                          >
                            Renewed Upto <span className="steric">*</span>
                          </label>
                          <div>
                            <DatePicker
                              className="form-control cat-select"
                              name="renew_upto"
                              autoComplete="off"
                              selected={this.state.formData.renew_upto}
                              onChange={this.handlerenew_upto}
                              minDate={new Date("1970/01/01")}
                            />
                          </div>
                        </div>
                      )}
                  </div>
                </div>
                {this.state.fieldsSet &&
                  this.state.fieldsSet.publication_type && (
                    <div className="mtb-20">
                      <label
                        htmlFor="publicationTypeInput"
                        className="fw-600-fs-13"
                      >
                        Publication Type <span className="steric">*</span>
                      </label>
                      <div id="publicationTypeGroup">
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="publication_type"
                            style={{ fontSize: "13px" }}
                            value="National"
                            onChange={this.handleCpdPoints}
                            id="publicationRadio1"
                          />
                          <label
                            className="form-check-label fs-12"
                            htmlFor="publicationRadio1"
                          >
                            National
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="publication_type"
                            style={{ fontSize: "13px" }}
                            value="International"
                            onChange={this.handleCpdPoints}
                            id="publicationRadio2"
                          />
                          <label
                            className="form-check-label fs-12"
                            htmlFor="publicationRadio2"
                          >
                            International
                          </label>
                        </div>
                      </div>
                    </div>
                  )}
                {this.state.fieldsSet && this.state.fieldsSet.event_type && (
                  <div className="mtb-20">
                    <label htmlFor="eventTypeInput" className="fw-600-fs-13">
                      Event Type <span className="steric">*</span>
                    </label>
                    <div id="eventTypeRadioGroup">
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="event_type"
                          style={{ fontSize: "13px" }}
                          value="National"
                          onChange={this.handleCpdPoints}
                          id="eventTypeRadio1"
                        />
                        <label
                          className="form-check-label fs-12"
                          htmlFor="eventTypeRadio1"
                        >
                          National
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="event_type"
                          value="International"
                          style={{ fontSize: "13px" }}
                          onChange={this.handleCpdPoints}
                          id="eventTypeRadio2"
                        />
                        <label
                          className="form-check-label fs-12"
                          htmlFor="eventTypeRadio2"
                        >
                          International
                        </label>
                      </div>
                    </div>
                  </div>
                )}
                {this.state.fieldsSet && this.state.fieldsSet.paper_type && (
                  <div className="mtb-20">
                    <label htmlFor="eventTypeInput" className="fw-600-fs-13">
                      Paper Type <span className="steric">*</span>
                    </label>
                    <div id="paperTypeGroup">
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="paper_type"
                          value="National"
                          style={{ fontSize: "13px" }}
                          onChange={this.handleCpdPoints}
                          id="eventTypeRadio1"
                        />
                        <label
                          className="form-check-label fs-12"
                          htmlFor="eventTypeRadio1"
                        >
                          National
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="paper_type"
                          value="International"
                          style={{ fontSize: "13px" }}
                          onChange={this.handleCpdPoints}
                          id="eeventTypeRadio2"
                        />
                        <label
                          className="form-check-label fs-12"
                          htmlFor="eeventTypeRadio2"
                        >
                          International
                        </label>
                      </div>
                    </div>
                  </div>
                )}
                {this.state.fieldsSet && this.state.fieldsSet.inst_type && (
                  <div className="mtb-20">
                    <label htmlFor="inst_type" className="fw-600-fs-13">
                      Institution Type <span className="steric">*</span>
                    </label>
                    <div id="institutionTypeGroup">
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          style={{ fontSize: "13px" }}
                          name="inst_type"
                          value="National"
                          onChange={this.handleChangeText}
                          id="institutionTypeRadio1"
                        />
                        <label
                          className="form-check-label fs-12"
                          htmlFor="institutionTypeRadio1"
                        >
                          National
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="inst_type"
                          value="International"
                          style={{ fontSize: "13px" }}
                          onChange={this.handleChangeText}
                          id="institutionTypeRadio2"
                        />
                        <label
                          className="form-check-label fs-12"
                          htmlFor="institutionTypeRadio2"
                        >
                          International
                        </label>
                      </div>
                    </div>
                  </div>
                )}

                {this.state.fieldsSet &&
                  this.state.fieldsSet.organizing_body && (
                    <div className="mtb-20">
                      <label
                        htmlFor="OrganizingBodyInput"
                        className="fw-600-fs-13"
                      >
                        Organizing Body <span className="steric">*</span>
                      </label>
                      <div id="organizingBodyGroup">
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="organizing_body"
                            style={{ fontSize: "13px" }}
                            value="Individual"
                            onChange={this.handleChangeText}
                            id="OrganizingBodyRadio1"
                          />
                          <label
                            className="form-check-label fs-12"
                            htmlFor="OrganizingBodyRadio1"
                          >
                            Individual
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="organizing_body"
                            value="NGO"
                            style={{ fontSize: "13px" }}
                            onChange={this.handleChangeText}
                            id="OrganizingBodyRadio2"
                          />
                          <label
                            className="form-check-label fs-12"
                            htmlFor="OrganizingBodyRadio2"
                          >
                            NGO
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="organizing_body"
                            value="Other"
                            style={{ fontSize: "13px" }}
                            onChange={this.handleChangeText}
                            id="OrganizingBodyRadio3"
                          />
                          <label
                            className="form-check-label fs-12"
                            htmlFor="OrganizingBodyRadio3"
                          >
                            Other
                          </label>
                        </div>
                      </div>
                    </div>
                  )}
                {this.state.fieldsSet &&
                  this.state.fieldsSet.membership_type && (
                    <div className="mtb-20">
                      <label htmlFor="DurationDYInput" className="fw-600-fs-13">
                        Membership Type <span className="steric">*</span>
                      </label>
                      <div id="membershipTypeGroup">
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="membership_type"
                            value="Yearly"
                            style={{ fontSize: "13px" }}
                            onChange={this.handleChangeText}
                            id="MembershipRadio1"
                          />
                          <label
                            className="form-check-label fs-12"
                            htmlFor="MembershipRadio1"
                          >
                            Yearly
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="membership_type"
                            value="Lifetime Membership"
                            style={{ fontSize: "13px" }}
                            onChange={this.handleChangeText}
                            id="MembershipRadio2"
                          />
                          <label
                            className="form-check-label fs-12"
                            htmlFor="MembershipRadio2"
                          >
                            Life Membership
                          </label>
                        </div>
                      </div>
                    </div>
                  )}

                {this.state.fieldsSet &&
                  this.state.fieldsSet.professional_body_type && (
                    <div className="mtb-20">
                      <label htmlFor="DurationDYInput" className="fw-600-fs-13">
                        Professional Body Type <span className="steric">*</span>
                      </label>
                      <div id="professionalBodyTypeGroup">
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="professional_body_type"
                            value="National"
                            style={{ fontSize: "13px" }}
                            onChange={this.handleCpdPoints}
                            id="InstitutionRadio1"
                          />
                          <label
                            className="form-check-label fs-12"
                            htmlFor="InstitutionRadio1"
                          >
                            Local Body
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="professional_body_type"
                            value="International"
                            style={{ fontSize: "13px" }}
                            onChange={this.handleCpdPoints}
                            id="InstitutionRadio2"
                          />
                          <label
                            className="form-check-label fs-12"
                            htmlFor="InstitutionRadio2"
                          >
                            Foreign Body
                          </label>
                        </div>
                      </div>
                    </div>
                  )}

                {this.state.fieldsSet &&
                  this.state.fieldsSet.nature_participation && (
                    <div className="mtb-20">
                      <label
                        htmlFor="natureOfParticipation"
                        className="fw-600-fs-13"
                      >
                        Nature Of Participation{" "}
                        <span className="steric">*</span>
                      </label>
                      <div id="nature_participation1">
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input "
                            type="radio"
                            name="nature_participation"
                            value="Attendee"
                            style={{ fontSize: "13px" }}
                            onChange={this.handleChangeText}
                            id="NatureRadio1"
                          />
                          <label
                            className="form-check-label fs-12"
                            htmlFor="NatureRadio1"
                          >
                            Attendee
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="nature_participation"
                            value="Host"
                            style={{ fontSize: "13px" }}
                            onChange={this.handleChangeText}
                            id="NatureRadio2"
                          />
                          <label
                            className="form-check-label fs-12"
                            htmlFor="NatureRadio2"
                          >
                            Host
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="nature_participation"
                            value="Resource Person"
                            style={{ fontSize: "13px" }}
                            onChange={this.handleChangeText}
                            id="NatureRadio3"
                          />
                          <label
                            className="form-check-label fs-12"
                            htmlFor="NatureRadio3"
                          >
                            Resource Person
                          </label>
                        </div>
                      </div>
                    </div>
                  )}
              </div>
              {this.state.fieldsSet && this.state.fieldsSet.course_work && (
                <div className="mtb-20 ">
                  <label htmlFor="course_workInput" className="fw-600-fs-13">
                    Course Work <span className="steric">*</span>
                  </label>

                  <div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="course_work"
                        value="course work only"
                        style={{ fontSize: "13px" }}
                        // value={this.state.course_work}
                        onChange={this.handleCpdPoints}
                        id="course_work1"
                      />
                      <label
                        className="form-check-label fs-12"
                        htmlFor="course_work1"
                      >
                        Course Work Only
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="course_work"
                        value="course work plus research"
                        style={{ fontSize: "13px" }}
                        // value={this.state.course_work}
                        onChange={this.handleCpdPoints}
                        id="course_work2"
                      />
                      <label
                        className="form-check-label fs-12"
                        htmlFor="course_work2"
                      >
                        Course Work Plus Research
                      </label>
                    </div>
                  </div>
                </div>
              )}

              {this.state.fieldsSet && this.state.fieldsSet.cpd_points_pec && (
                <div className="CPD-group">
                  <div className="mtb-20 App">
                    <label htmlFor="cpd_points_pec" className="fs-24 fw-600">
                      CPD Points As Per PEC By Laws &nbsp; {"       "}
                      <span className="dark-green fs-30 fw-600">
                        {"                " +
                          this.state.formData.cpd_points_pec}
                      </span>
                    </label>
                  </div>
                  <div className="mtb-20">
                    <label htmlFor="cpd_points_user" className="fw-600-fs-13">
                      CPD Points As Per User <span className="steric">*</span>
                    </label>
                    <input
                      type="number"
                      className="form-control fields-input-cpd"
                      min={0}
                      id="cpd_points_user"
                      name="cpd_points_user"
                      style={{ fontSize: "13px" }}
                      onChange={this.handleChangeText}
                      value={this.state.formData.cpd_points_user}
                      aria-describedby="emailHelp"
                    />
                  </div>

                  <div className="mtb-20">
                    <label
                      htmlFor="certificate_number"
                      className="fw-600-fs-13"
                    >
                      Certificate Number <span className="steric">*</span>
                    </label>
                    <input
                      type="number"
                      className="form-control fields-input-cpd"
                      min={0}
                      maxLength={20}
                      id="certificate_number"
                      name="certificate_number"
                      style={{ fontSize: "13px" }}
                      onChange={this.handleChangeText}
                      value={this.state.formData.certificate_number}
                      aria-describedby="emailHelp"
                    />
                  </div>

                  <div className=" mt-40">
                    <label htmlFor="CPDupload" className="fw-600-fs-13">
                      Upload Certificate / Document{" "}
                      <span className="steric">*</span>
                    </label>
                  </div>
                  {this.state.formData.upload ? (
                    <div className="row card-body">
                      <div className="col-md-12 live_web_border_0">
                        <div className="row ptb-20">
                          <div className="col-md-4 col_padding ">
                            <div className="pl-lg-5">
                              {/* <img
                                width="150"
                                height="100"
                                src={this.state.image64}
                              /> */}
                              {/* `{console.log('Current Filename Type of Data',this.state.currentFileTypeofData)}` */}

                              {this.state.currentFileTypeofData.match(
                                "image.*"
                              ) ? (
                                <img
                                  width="150"
                                  height="100"
                                  src={this.state.image64}
                                />
                              ) : (
                                <i
                                  className={
                                    this.state.currentFileTypeofData ===
                                    "application/pdf"
                                      ? "far fa-file-pdf fa-3x pb-10"
                                      : this.state.currentFileTypeofData ===
                                          "application/msword" ||
                                        this.state.currentFileTypeofData ===
                                          "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                      ? "far fa-file-word fa-3x pb-10"
                                      : ""
                                  }
                                />
                              )}
                              <p style={{ marginTop: "10px" }}>
                                {this.state.currentFileNameofData}
                              </p>
                              {/* <img
                              src={require("../../assets/image/designer_icon/Upload-files-here.png")}
                            /> */}
                            </div>
                          </div>
                          <div className="col-md-4">
                            <p className="fz_16 pt-2 pl-4">
                              {this.state.formData.upload.path}
                            </p>
                          </div>
                          <div className="col-md-4 flex_center col_padding">
                            <div className="">
                              <button
                                className="cancel-file-btn"
                                onClick={() => this.delete()}
                              >
                                <i
                                  className="fa fa-trash delbutton"
                                  aria-hidden="true"
                                />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div
                      className="text-center"
                      id="dropZone"
                      style={{
                        color: "black",
                        border: "1px solid #719e54",
                        padding: 10,
                        borderRadius: 4,
                        cursor: "pointer",
                        height: "150px",
                        width: "auto",
                        padding: "15px",
                      }}
                    >
                      {" "}
                      <Dropzone
                        name="dropzone1"
                        onDrop={this.handleOnDropImage}
                        maxSize={2e6}
                        multiple={false}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <section>
                            <div {...getRootProps()}>
                              <input {...getInputProps()} />
                              <i
                                className="fa fa-image fa-3x pb-10"
                                style={{ color: "#20A84C" }}
                              />
                              <br />
                              <span className="fs-10">
                                Click Or Drag File To Upload Here.
                                <br />
                                File Should Be Less Than 2 Mbs.
                                <br />
                                Only Word, PDF And Images Are Supported
                              </span>
                            </div>
                          </section>
                        )}
                      </Dropzone>
                    </div>
                  )}

                  {this.state.fieldsSet ? (
                    <>
                      <div className="mtb-20 ">
                        <label
                          htmlFor="rOsuggestionInput"
                          className="fw-600-fs-13"
                        >
                          Additional Information
                        </label>
                        <textarea
                          className="form-control fields-input-cpd"
                          name="suggestion"
                          id="suggestionInput"
                          style={{ fontSize: "13px" }}
                          value={this.state.formData.suggestion}
                          onChange={this.handleTextChange}
                          rows="5"
                          cols="33"
                        ></textarea>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              )}
              <div className="warning-text mt-40">
                Fields marked with (*) are mandatory. Please fill all the
                mandatory fields to submit form
              </div>
              <div className="success-text mt-40">
                Select category and sub category from above dropdowns to claim
                more CPD points
              </div>

              <button
                type="submit"
                id="submit-button"
                onClick={this.formHandle}
                className="btn btn-success btn-lg btn-block mt-20"
                {...(this.state.formData.cpd_points_pec === null ||
                this.state.formData.cpd_points_pec === "" ||
                this.state.formData.cpd_points_pec === 0
                  ? { disabled: true }
                  : { disabled: false })}
              >
                Add CPD Points
              </button>
              {this.state.formList && this.state.formList.length > 0 ? (
                <MUIDataTable
                  title={"CPD Claims"}
                  data={this.state.formMui && this.state.formMui}
                  columns={[
                    "Category",
                    "Subcategory",
                    "CPD Points As Per PEC By Laws",
                  ]}
                  options={options}
                />
              ) : (
                ""
              )}
              {this.state.formList && this.state.formList.length > 0 ? (
                <>
                  {this.state.setFinalApprovalLoad === true ? (
                    <div className="loaderDiv text-center">
                      <Loader
                        type="Oval"
                        color="#06580e"
                        height={80}
                        width={80}
                      />
                    </div>
                  ) : (
                    <>
                      <button
                        type="submit"
                        id="submit-button"
                        onClick={this.submitFinal}
                        className="btn btn-success btn-lg btn-block mt-20"
                        disabled={this.state.setFinalApprovalLoad}
                        {...(this.state.formData.cpd_points_pec === null ||
                        this.state.formData.cpd_points_pec === "" ||
                        this.state.formData.cpd_points_pec === 0
                          ? { disabled: true }
                          : { disabled: false })}
                      >
                        Submit For Approval
                      </button>
                    </>
                  )}
                </>
              ) : (
                ""
              )}
            </LoadingOverlay>

            {/* {this.state.loader ? (
              <center>
                {" "}
                <Loader type="Oval" style={{ positon: "absolute", top: 0 }} />
              </center>
            ) : (
              <button
                type="submit"
                id="submit-button"
                onClick={this.formHandle}
                className="btn btn-success btn-lg btn-block mt-20"
                {...(this.state.formData.cpd_points_pec === null ||
                this.state.formData.cpd_points_pec === "" ||
                this.state.formData.cpd_points_pec === 0
                  ? { disabled: true }
                  : { disabled: false })}
              >
                Claim CPD Points
              </button>
            )} */}
          </form>
        </div>
      </React.Fragment>
    );
  }
}
function mapStateToProps(state) {
  return {
    fields: state.userData.fielddata,
    User: state.userData.user,
  };
}
export default connect(mapStateToProps, {
  getFields,
  getSubCategories,
  getClaimBasedSubmission,
  getSubFields,
  getPoints,
  submitCPDForm,
  getUserSubmission,
  getUserDuplicateRecords,
})(ClaimCPD);
